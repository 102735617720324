import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./redactionQuiz.module.scss";
import { getLessonById_getLessonById } from "../../../../__generated__/gql-types/getLessonById";
import { HeaderRedaction } from "./HeaderRedaction";
import { FooterRedaction } from "./FooterRedaction";
import { FinishRedaction } from "./FinishRedaction";
import UseGetResponseByUserAndRedactionHomework from "../../../../api/useGetResponseByUserAndRedactionHomework";
import { getResponseByUserAndRedactionHomework_getResponseByUserAndRedactionHomework } from "../../../../__generated__/gql-types/getResponseByUserAndRedactionHomework";
import { getUserByEmail_getUserByEmail } from "../../../../__generated__/gql-types/getUserByEmail";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { getHomeworkById_getHomeworkById } from "../../../../__generated__/gql-types/getHomeworkById";
import { useLocation } from "react-router-dom";
import UseGetHomeworkById from "../../../../api/useGetHomeworksById";
interface PropsRedaction {
  lesson: getLessonById_getLessonById | null;
  userToken: getUserByEmail_getUserByEmail | null;
  homeworkId: string | null;
}

const RedactionQuiz: FC<PropsRedaction> = ({
  lesson,
  userToken,
  homeworkId,
}) => {
  const getResponseByUserAndRedactionHomework =
    UseGetResponseByUserAndRedactionHomework();
  const location: any = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [redactionText, setRedactionText] = useState("");
  const [result, setResult] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>();
  const homeworkParams = queryParams.get("homework");
  const [isLoading, setIsLoading] = useState(false);
  const editorRef = useRef<Editor>(null);
  const getHomeworkById = UseGetHomeworkById();
  const [homework, setHomework] =
    useState<getHomeworkById_getHomeworkById | null>(null);
  const [
    responseByUserAndRedactionHomework,
    setResponseByUserAndRedactionHomework,
  ] =
    useState<getResponseByUserAndRedactionHomework_getResponseByUserAndRedactionHomework | null>(
      null
    );

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setRedactionText(content);
  };
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      if (homeworkParams) {
        const taskRes = await getHomeworkById({ homeworkId: homeworkParams });
        taskRes && setHomework(taskRes);
      }
    })();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const resultResponseByUser =
          await getResponseByUserAndRedactionHomework({
            homeworkId: Number(homeworkId) ?? null,
            redactionId: Number(lesson?.lesson_content?.redaction?.id) ?? null,
            userId: Number(userToken?.id) ?? null,
          });

        resultResponseByUser &&
          setResponseByUserAndRedactionHomework(resultResponseByUser);

        resultResponseByUser?.redaction_response &&
          setRedactionText(resultResponseByUser.redaction_response);

        if (resultResponseByUser) {
          setEditorState(() => {
            if (resultResponseByUser?.redaction_response) {
              const contentBlock = htmlToDraft(
                resultResponseByUser.redaction_response
              );
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
              );
              return EditorState.createWithContent(contentState);
            } else {
              return EditorState.createEmpty();
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [
    getResponseByUserAndRedactionHomework,
    lesson?.lesson_content?.redaction?.id,
    homeworkId,
    lesson,
    userToken,
  ]);

  useEffect(() => {
    // Verificar y enfocar el editor en el montaje del componente
    if (editorRef.current) {
      editorRef.current.focusEditor();
    }
  }, []);
  const handlePastedText = (
    text: string,
    html: string,
    editorState: EditorState,
    onChange: (editorState: EditorState) => void
  ) => {
    return true;
  };
  return result ? (
    <FinishRedaction homework={homework} />
  ) : (
    <div className={styles.container}>
      <HeaderRedaction lesson={lesson} homework={homework} />

      <div className={styles.content}>
        {lesson?.lesson_content.redaction?.question.includes("Evidencia:") ? (
          <>
            <div className={styles.titleContent}>
              {
                lesson?.lesson_content.redaction?.question.split(
                  "Evidencia:"
                )[0]
              }
            </div>
            <div className={styles.titleContent}>
              Evidencia:
              {
                lesson?.lesson_content.redaction?.question.split(
                  "Evidencia:"
                )[1]
              }
            </div>
          </>
        ) : (
          <div className={styles.titleContent}>
            {lesson?.lesson_content.redaction?.question}
          </div>
        )}
        <div className={styles.containerEditText}>
          <Editor
            ariaAutoComplete="off"
            editorState={editorState}
            readOnly={isLoading}
            ref={editorRef}
            placeholder="Escribe aquí tu respuesta"
            editorStyle={{
              backgroundColor: "white",
              height: "60vh",
              borderRadius: 12,
              padding: 10,
              overflow: "hidden",
            }}
            toolbarHidden={true}
            localization={{
              locale: "es",
            }}
            handlePastedText={handlePastedText}
            onEditorStateChange={onEditorStateChange}
          />
        </div>
      </div>
      <FooterRedaction
        responseByUserAndRedactionHomework={responseByUserAndRedactionHomework}
        userToken={userToken}
        homeworkId={homeworkId}
        lesson={lesson}
        redactionText={redactionText}
        setResult={setResult}
      ></FooterRedaction>
    </div>
  );
};

export default RedactionQuiz;
