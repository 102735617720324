import React, { FC, useEffect, useState } from "react";
import styles from "./conversation.module.scss";
import {
  PropsConversation,
  PropsMessage,
  allowedRoles,
  apiKeyChatGpt,
  understoodVariants,
} from "./utils";
import axios from "axios";
import { QuizHeader } from "../Header";
import { ChatConversation } from "./ChatConversation";
import {
  handleAudioNegativeFinish,
  handleAudioPositiveFinish,
  useFetchLesson,
} from "../utils";
import useAuth from "../../../../../context/useAuth";
import { environment } from "../../../../../configuration/environment";
import { useLocation, useNavigate } from "react-router-dom";
import ResultConversation from "./ResultConversation/ResultConversation";
import UseCreateChat from "../../../../../api/useCreateChat";
import {
  errorCorrectionPrompt,
  goodbyePrompt,
  mainPrompt,
  speakingPracticePrompt,
  speakingResponsePrompt,
} from "./prompts";

const Conversation: FC<PropsConversation> = ({
  individualLesson,
  handleInstructions,
  statusHomework,
}) => {
  let questions =
    individualLesson.lesson_content.aiconversations?.section_1 &&
    individualLesson.lesson_content.aiconversations?.section_2
      ? individualLesson.lesson_content.aiconversations?.section_1.concat(
          individualLesson.lesson_content.aiconversations?.section_2
        )
      : [];
  const { userToken, token } = useAuth();
  const navigate = useNavigate();
  const location: any = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const homeworkParams = queryParams.get("homework");
  const [contQuestion, setContQuestion] = useState(0);
  const [messages, setMessages] = useState<PropsMessage[]>([]);
  const [isFinished, setIssFinished] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [result, setResult] = useState<any | null>(null);
  const [contResult, setContResult] = useState<number>(0);
  const createChatLog = UseCreateChat();
  const { sound, setSound } = useFetchLesson();

  const functionUnderstoodIsFalse = async (userMessage: string) => {
    const propmtsAlumn = [
      {
        role: "user",
        content: `You asked this: ${questions[contQuestion - 1].question}.
        Student responded this: ${userMessage}
        Explain to the student, en B2 español, qué quería decir la pregunta y darles una sugerencia de una respuesta ejemplar en inglés (write this suggestion in english, but the rest of your comment in Spanish). Por ejemplo “Te pregunté sobre tu edad, ejemplo de respuesta “I am 6 years old.”” Solo 25 palabras maximo.`,
      },
    ];
    console.log({
      propmt: `You asked this: ${questions[contQuestion - 1].question}.
        Student responded this: ${userMessage}
        Explain to the student, en B2 español, qué quería decir la pregunta y darles una sugerencia de una respuesta ejemplar en inglés (write this suggestion in english, but the rest of your comment in Spanish). Por ejemplo “Te pregunté sobre tu edad, ejemplo de respuesta “I am 6 years old.”” Solo 25 palabras maximo.`,
    });

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );
    setMessages([
      ...messages,
      {
        message: userMessage,
        sender: "sender",
        messageUser: response.data.choices[0].message.content,
      },
      {
        message: response.data.choices[0].message.content,
        notUnderstood: true,
        sender: "LUCA",
        messageUser: response.data.choices[0].message.content,
      },
    ]);
  };

  const functionGoodBye = async () => {
    const propmtsAlumn = [
      goodbyePrompt({ contQuestion, individualLesson, questions }),
    ];
    console.log({
      propmt: goodbyePrompt({ contQuestion, individualLesson, questions })
        .content,
    });

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );

    return response.data.choices[0].message.content as string;
  };

  const functionFixedError = async (
    userMessage: string,
    maxIntentos: number,
    intentoActual = 1
  ) => {
    const propmtsAlumn = [errorCorrectionPrompt({ userMessage })];
    console.log({
      propmt: errorCorrectionPrompt({ userMessage }).content,
    });

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );

    if (response.data.choices[0].message.content.includes("Errors:")) {
      return response.data.choices[0].message.content.replace(
        /```/g,
        ""
      ) as string;
    } else if (intentoActual < maxIntentos) {
      functionFixedError(userMessage, maxIntentos, intentoActual + 1);
    } else {
      console.log("Se alcanzó el máximo de intentos");
    }
  };

  const functionNextQuestions = async (userMessage: string) => {
    const propmtsAlumn = speakingResponsePrompt({
      contQuestion,
      individualLesson,
      questions,
      userMessage,
    });
    console.log(
      speakingResponsePrompt({
        contQuestion,
        individualLesson,
        questions,
        userMessage,
      })
    );

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );

    return response.data.choices[0].message.content as string;
  };

  const handleSendAlumn = async (userMessage: string) => {
    setIsTyping(true);
    setMessages([
      ...messages,
      {
        message: userMessage,
        sender: "sender",
      },
    ]);

    const propmtsAlumn = [
      {
        role: "user",
        content: mainPrompt({
          individualLesson,
          questions,
          contQuestion,
          userMessage,
        }),
      },
    ];
    console.log({
      propmt: mainPrompt({
        individualLesson,
        questions,
        contQuestion,
        userMessage,
      }),
    });

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKeyChatGpt}`,
          },
        }
      );

      if (
        understoodVariants.some((variant) =>
          response.data.choices[0].message.content.includes(variant)
        )
      ) {
        await functionUnderstoodIsFalse(userMessage);
      } else {
        const resultado =
          response.data.choices[0].message.content.match(/\[(.*?)\]/);
        const valorEncerrado = resultado ? resultado[1] : null;

        console.log(
          Number(valorEncerrado),
          "si solo muestra esta nota y no las demas es porque se rompio"
        );
        if (Number(valorEncerrado)) {
          setContResult(
            messages[messages.length - 1].notUnderstood === true
              ? contResult + 5 / questions.length
              : contResult + Number(valorEncerrado) / questions.length
          );
          console.log({ notaAntesDeSumar: contResult });
          console.log({
            notaQueRecibioPorGPT: Number(valorEncerrado),
          });

          console.log({
            notaQueSumaElAlumno:
              messages[messages.length - 1].notUnderstood === true
                ? `${
                    5 / questions.length
                  } (5 / total questons, por no comprender la pregunta)`
                : Number(valorEncerrado) / questions.length,
          });

          console.log({
            notaAcumulada:
              messages[messages.length - 1].notUnderstood === true
                ? contResult + 5 / questions.length
                : contResult + Number(valorEncerrado) / questions.length,
          });
        }

        let errorUser =
          response.data.choices[0].message.content.includes("[YES]") ||
          response.data.choices[0].message.content.includes("YES")
            ? await functionFixedError(userMessage, 3)
            : null;

        let nextQuestions = await functionNextQuestions(userMessage);

        if (contQuestion === questions.length) {
          const responseGoodBye = await functionGoodBye();
          let localMessages = [
            ...messages,
            {
              message: userMessage,
              sender: "sender",
              messageUser: response.data.choices[0].message.content,
              errorUser: errorUser,
            },
            {
              message: nextQuestions,
              sender: "LUCA",
              position: "second_to_last",
              notUnderstood: false,
              messageUser: response.data.choices[0].message.content,
            },
            {
              message: responseGoodBye,
              sender: "LUCA",
              position: "last",
              notUnderstood: false,
              messageUser: response.data.choices[0].message.content,
            },
          ];
          setIssFinished(true);
          setMessages(localMessages);
        } else {
          let localMessages = [
            ...messages,
            {
              message: userMessage,
              sender: "sender",
              messageUser: response.data.choices[0].message.content,
              errorUser: errorUser,
            },
            {
              message: nextQuestions,
              sender: "LUCA",
              notUnderstood: false,
              messageUser: response.data.choices[0].message.content,
            },
          ];
          setMessages(localMessages);
        }
        setContQuestion(contQuestion + 1);
      }
    } catch (error) {
      setMessages([
        {
          message: "Tenemos un error, por favor reintentá más tarde.",
          sender: "LUCA",
        },
      ]);
    }
    setIsTyping(false);
  };

  const handleResult = async () => {
    Math.ceil(contResult) > 5
      ? sound && handleAudioPositiveFinish()
      : sound && handleAudioNegativeFinish();
    if (userToken?.role && allowedRoles.includes(userToken?.role)) {
      setResult({
        __typename: "EvaluateLessonResponse",
        firstTryWith10: null,
        isCourseApproved: Math.ceil(contResult) > 6 ? true : false,
        result: Math.ceil(contResult),
        userWonMedal: null,
        homework_tries: null,
      });
    } else {
      try {
        const newMessages = messages.map(({ position, ...rest }) => rest);
        await createChatLog({
          aiChatLogInput: {
            homework_id: homeworkParams ? Number(homeworkParams) : null,
            user_id: Number(userToken?.id),
            chat_log: newMessages,
          },
        });

        const response = await axios.post(
          `${environment.backendUrl}/evaluateLessonV3`,
          {
            evaluateLessonInputV3: {
              quiz_result: statusHomework?.post_homework
                ? null
                : Math.ceil(contResult),
              homework_id: homeworkParams ? Number(homeworkParams) : null,
              lesson_id: Number(individualLesson?.id),
              post_homework_result: statusHomework?.post_homework
                ? Math.ceil(contResult)
                : null,
            },
          },
          {
            headers: {
              token,
            },
          }
        );
        setResult(response?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setIsTyping(true);
      const payload = {
        model: "gpt-4o", // Asegúrate de usar el modelo correcto disponible en ese momento
        messages: [
          speakingPracticePrompt({ contQuestion, individualLesson, questions }),
        ],
      };

      console.log({
        propmt: speakingPracticePrompt({
          contQuestion,
          individualLesson,
          questions,
        }).content,
      });

      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKeyChatGpt}`,
            },
          }
        );
        setMessages([
          {
            message: response.data.choices[0].message.content,
            sender: "LUCA",
          },
        ]);
        setContQuestion(1);
      } catch (error) {
        setMessages([
          {
            message: "Tenemos un error, por favor reintentá más tarde.",
            sender: "LUCA",
          },
        ]);
      }
      setIsTyping(false);
    })();
  }, [individualLesson]);

  return (
    <div className={styles.container}>
      {result ? (
        <ResultConversation
          result={result}
          individualLesson={individualLesson}
          activeSound={sound}
          messages={messages}
        />
      ) : (
        <>
          <QuizHeader
            individualLesson={individualLesson}
            selectedKeyLiteracy={"1"}
            total={questions.length}
            contProgress={contQuestion}
            handleInstructions={handleInstructions}
            sound={sound}
            setSound={setSound}
          />

          <ChatConversation
            isFinished={isFinished}
            messages={messages}
            isTyping={isTyping}
            activeSound={sound}
            individualLesson={individualLesson}
            handleSendAlumn={handleSendAlumn}
          />
        </>
      )}
      <div
        className={isFinished ? styles.botonNormal : styles.botonBloqued}
        onClick={() => {
          if (isFinished) {
            result ? navigate(-1) : handleResult();
          }
        }}
      >
        {result ? "CONTINUE" : "FINISH "}
      </div>
    </div>
  );
};

export default Conversation;
