import { FC, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import styles from "./studentStatusTable.module.scss";
import download from "../../../../assets/icons/teacher/download_table.svg";
import noDownloader from "../../../../assets/icons/teacher/noDownloader.svg";
import {
  getHomeworkById_getHomeworkById,
  getHomeworkById_getHomeworkById_students_grades,
} from "../../../../__generated__/gql-types/getHomeworkById";
import { resolveAvatar, resolveColorAvatar } from "../../../../utils/functions";
import useIsMobile from "../../../../hooks/useIsMobile";
import { environment } from "../../../../configuration/environment";
import nextArrow from "../../../../assets/icons/teacher/next_arrow_blue.svg";
import { checkStatus } from "./utils";
import { MessageWeb } from "./MessageWeb";
import useEditHomeworkEventResult from "../../../../api/useEditHomeworkEventResult";
import { TeacherEventCorrectionInput } from "../../../../__generated__/gql-types/globalTypes";

interface Props {
  task?: getHomeworkById_getHomeworkById | null;
  setUserIdSetted: (e: number | null) => void;
  setVisibleDrawer: (e: boolean) => void;
  setVisibleDrawerConversation: (e: boolean) => void;
  setVisibleModalStudentResponse: React.Dispatch<React.SetStateAction<boolean>>;
  setTask: (e: getHomeworkById_getHomeworkById) => void;
}

const StudentsStatusTable: FC<Props> = ({
  task,
  setUserIdSetted,
  setTask,
  setVisibleDrawer,
  setVisibleDrawerConversation,
  setVisibleModalStudentResponse,
}) => {
  const isMobile = useIsMobile();
  const editHomeworkEventResult = useEditHomeworkEventResult();
  const [visibleMessage, setVisibleMessage] = useState(false);
  useState<TeacherEventCorrectionInput | null>(null);

  const [inputNote, setInputNote] = useState(
    task?.students_grades?.map(() => ({
      isInputNote: false,
    })) ?? []
  );
  const homeworkId = Number(task?.id);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    _: getHomeworkById_getHomeworkById_students_grades
  ) => {
    if (
      !(
        (e.key >= "0" && e.key <= "9") || //numbers
        e.key === "Backspace" || //backspace
        e.key === "ArrowLeft" || //left arrow
        e.key === "ArrowRight" || //right arrow
        e.key === "Tab" || //tab
        e.key === "Enter" || //enter
        e.key === "."
      )
    ) {
      e.preventDefault();
    }
    if (e.key === "Enter") {
      const newValue =
        Number(e.currentTarget.value) < 0
          ? 0
          : Number(e.currentTarget.value) > 10
          ? 10
          : Number(e.currentTarget.value);
      editHomeworkEventResult({
        homework_id: homeworkId,
        new_result: newValue,
        student_id: _.student_id,
      });

      let newTask = {
        ...task,
        students_grades: task?.students_grades?.map((student) =>
          student.student_id === _.student_id
            ? { ...student, high_score: newValue }
            : student
        ),
      };
      setTask(newTask as getHomeworkById_getHomeworkById);
      setInputNote(
        task?.students_grades?.map(() => ({
          isInputNote: false,
        })) ?? []
      );
    }
  };

  let columns: ColumnsType<getHomeworkById_getHomeworkById_students_grades> = [
    {
      title: "ALUMNOS",
      dataIndex: "student_name",
      key: "student_name",
      align: "left",
      sorter: {
        compare: (a, b) =>
          a?.student_last_name!.localeCompare(b?.student_last_name!, "es"),
        multiple: 1,
      },
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 400,
      render: (text, _) => {
        return (
          <div className={styles.userContainer}>
            <span
              className={styles.studentAvatar}
              style={{
                background: `${resolveColorAvatar(
                  _?.student_avatar ?? "polux"
                )}`,
              }}
            >
              <img
                src={resolveAvatar(_?.student_avatar ?? "polux", "5")}
                alt="avatar"
              />
            </span>
            <h1> {_.student_name}</h1>
          </div>
        );
      },
    },
    {
      title: () => <h1 className={styles.tableTitles}>ESTADO</h1>,
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      render: (i, record) => {
        return (
          <div className={styles.deliveredContent}>
            <p
              className={
                checkStatus(record.status) === "No entregada"
                  ? styles.notDelivered
                  : checkStatus(record.status) === "Completada"
                  ? styles.delivered
                  : styles.inCourse
              }
            >
              {checkStatus(record.status)}
            </p>
          </div>
        );
      },
    },
    {
      title: () => <h1 className={styles.tableTitles}>INTENTOS</h1>,
      dataIndex: "tries",
      key: "tries",
      align: "center",
      width: 100,
      render: (i, _) => {
        return !task?.tries || !_?.tries ? (
          _?.tries ? (
            <div className={styles.tries}> {_?.tries?.toString()}</div>
          ) : (
            <div className={styles.textStyles}>-</div>
          )
        ) : (
          <div className={styles.tries}>
            {_?.tries?.toString()}/{task?.tries}
          </div>
        );
      },
    },
    {
      title: () => <h1 className={styles.tableTitles}>MEJOR</h1>,
      dataIndex: "high_score",
      key: "high_score",
      width: 100,
      align: "center",
      sorter: { compare: (a, b) => a.high_score! - b.high_score!, multiple: 2 },
      sortDirections: ["ascend", "descend", "ascend"],
      render: (i, _, index) => {
        return (
          <div
            className={styles.tabScoreContainer}
            onClick={(e) => {
              e.stopPropagation();
              const newArray = inputNote?.map((e, indexTool) => ({
                ...e,
                isInputNote: index === indexTool ? true : false,
              }));
              setInputNote(newArray);
            }}
          >
            {typeof _.high_score === "number" ? (
              <div className={styles.containerInputAndNote}>
                <input
                  className={styles.boxQualification}
                  type="number"
                  min={0}
                  max={10}
                  step={1}
                  defaultValue={_.high_score ?? undefined}
                  style={{
                    display: inputNote?.[index].isInputNote ? "flex" : "none",
                  }}
                  onKeyDown={(e) => handleKeyDown(e, _)}
                  onInput={(e) => {
                    const target = e.target as HTMLInputElement;
                    const value = target.value;
                    //check if the value is a valid number or contains a "."
                    if (!value || value.endsWith(".")) {
                      //allow the user to temporarily type a "."
                      return;
                    }

                    //convert the value to a number and round it
                    let numericValue = parseFloat(value);

                    //ensure it is a number within the range (0-10)
                    numericValue = Math.min(
                      Math.max(Math.round(numericValue), 0),
                      10
                    );

                    //update the rounded value in the input
                    target.value = numericValue.toString();
                  }}
                />
                <div
                  className={
                    _.high_score > 5
                      ? styles.tableHighScore
                      : styles.tableLowScore
                  }
                  style={{
                    display: inputNote?.[index].isInputNote ? "none" : "flex",
                  }}
                >
                  {_.high_score}
                </div>
              </div>
            ) : (
              <div className={styles.textStyles}>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: () => <h1 className={styles.tableTitles}>EVIDENCIA</h1>,
      key: "evidence",
      align: "center",
      width: 100,
      render: (record: any) => {
        const url = `${environment.gcpBucketStudentEvidence}/${record.studentEvidenceUrl}`;
        return record.studentEvidenceUrl ? (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{ cursor: "pointer" }}
          >
            <img src={download} alt="download" /> 
          </a>
        ) : (
          <div className={styles.noDownloaderDisable}>
            <img src={noDownloader} alt="download" />
          </div>
        );
      },
    },
    {
      width: 8,
      render: (record: any) => {
        const status = checkStatus(record.status);
        if (status === "Completada") {
          return (
            <img
              src={nextArrow}
              alt=""
              className={styles.arrow}
              onClick={() => {
                setVisibleModalStudentResponse(true);
                setUserIdSetted(record?.student_id);
              }}
            />
          );
        } else {
          return null;
        }
      },
    },
  ];

  let columnsGPT: ColumnsType<getHomeworkById_getHomeworkById_students_grades> =
    [
      {
        title: "ALUMNO",
        dataIndex: "student_name",
        key: "student_name",
        align: "left",
        sorter: {
          compare: (a, b) =>
            a?.student_name!.localeCompare(b?.student_name!, "es"),
          multiple: 1,
        },
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 400,
        render: (text, _) => {
          return (
            <div className={styles.userContainer}>
              <span
                className={styles.studentAvatar}
                style={{
                  background: `${resolveColorAvatar(
                    _?.student_avatar ?? "polux"
                  )}`,
                }}
              >
                <img
                  src={resolveAvatar(_?.student_avatar ?? "polux", "5")}
                  alt="avatar"
                />
              </span>
              <h1> {_.student_name}</h1>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>ESTADO</h1>,
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 100,
        render: (i, record) => {
          return (
            <div className={styles.deliveredContent}>
              <p
                className={
                  checkStatus(record.status) === "No entregada"
                    ? styles.notDelivered
                    : checkStatus(record.status) === "Completada"
                    ? styles.delivered
                    : styles.inCourse
                }
              >
                {checkStatus(record.status)}
              </p>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>Calificacion</h1>,
        dataIndex: "delivered",
        key: "delivered",
        align: "center",
        width: 100,
        render: (i, _, index) => {
          return _.tries ? (
            typeof _.high_score !== "number" ? (
              <div
                className={styles.fixedStyles}
                onClick={() => {
                  setUserIdSetted(Number(_.student_id) ?? null);
                  setVisibleDrawer(true);
                }}
              >
                corregir
              </div>
            ) : (
              <div className={styles.tabScoreContainer}>
                <div
                  className={
                    _.high_score > 5
                      ? styles.tableHighScore
                      : styles.tableLowScore
                  }
                >
                  {_.high_score}
                </div>
              </div>
            )
          ) : (
            <div className={styles.textStyles}>-</div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>EVIDENCIA</h1>,
        key: "evidence",
        align: "center",
        width: 100,
        render: (record: any, ) => {
          const url = `${environment.gcpBucketStudentEvidence}/${record.studentEvidenceUrl}`;
          return record.studentEvidenceUrl ? (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                event.isPropagationStopped();
              }}
            >
              <img src={download} alt="download" /> 
            </a>
          ) : (
            <div className={styles.noDownloaderDisable}>
              <img src={noDownloader} alt="download" />
            </div>
          );
        },
      },
      {
        key: "action",
        align: "center",
        width: 8,
        render: (i, _) => {
          return !_.tries ? (
            <div className={styles.textStyles}></div>
          ) : (
            <img
              src={nextArrow}
              alt=""
              className={styles.arrow}
              onClick={() => {
                setUserIdSetted(Number(_.student_id) ?? null);
                setVisibleDrawer(true);
              }}
            />
          );
        },
      },
    ];

  let columnsConversation: ColumnsType<getHomeworkById_getHomeworkById_students_grades> =
    [
      {
        title: "ALUMNO",
        dataIndex: "student_name",
        key: "student_name",
        sorter: {
          compare: (a, b) =>
            a?.student_name!.localeCompare(b?.student_name!, "es"),
          multiple: 1,
        },
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 400,
        render: (text, _) => {
          return (
            <div className={styles.userContainer}>
              <span
                className={styles.studentAvatar}
                style={{
                  background: `${resolveColorAvatar(
                    _?.student_avatar ?? "polux"
                  )}`,
                }}
              >
                <img
                  src={resolveAvatar(_?.student_avatar ?? "polux", "5")}
                  alt="avatar"
                />
              </span>
              <h1> {_.student_name}</h1>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>ESTADO</h1>,
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 100,
        render: (i, record) => {
          return (
            <div className={styles.deliveredContent}>
              <p
                className={
                  checkStatus(record.status) === "No entregada"
                    ? styles.notDelivered
                    : checkStatus(record.status) === "Completada"
                    ? styles.delivered
                    : styles.inCourse
                }
              >
                {checkStatus(record.status)}
              </p>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>Calificacion</h1>,
        dataIndex: "delivered",
        key: "delivered",
        align: "center",
        width: 100,
        render: (i, _, index) => {
          return (
            <div
              className={styles.tabScoreContainer}
              onClick={(e) => {
                e.stopPropagation();
                const newArray = inputNote?.map((e, indexTool) => ({
                  ...e,
                  isInputNote: index === indexTool ? true : false,
                }));
                setInputNote(newArray);
              }}
            >
              {typeof _.high_score === "number" ? (
                <div className={styles.containerInputAndNote}>
                  <input
                    className={styles.boxQualification}
                    type="number"
                    min={0}
                    max={10}
                    step={1}
                    defaultValue={_.high_score ?? undefined}
                    style={{
                      display: inputNote?.[index].isInputNote ? "flex" : "none",
                    }}
                    onKeyDown={(e) => handleKeyDown(e, _)}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      //check if the value is a valid number or contains a "."
                      if (!value || value.endsWith(".")) {
                        //allow the user to temporarily type a "."
                        return;
                      }

                      //convert the value to a number and round it
                      let numericValue = parseFloat(value);

                      //ensure it is a number within the range (0-10)
                      numericValue = Math.min(
                        Math.max(Math.round(numericValue), 0),
                        10
                      );

                      //update the rounded value in the input
                      target.value = numericValue.toString();
                    }}
                  />
                  <div
                    className={
                      _.high_score > 5
                        ? styles.tableHighScore
                        : styles.tableLowScore
                    }
                    style={{
                      display: inputNote?.[index].isInputNote ? "none" : "flex",
                    }}
                  >
                    {_.high_score}
                  </div>
                </div>
              ) : (
                <div className={styles.textStyles}>-</div>
              )}
            </div>
          );
        },
      },

      {
        key: "action",
        align: "center",
        width: 8,
        render: (i, _) => {
          return !_.delivered ? (
            <div className={styles.textStyles}>- -</div>
          ) : (
            <img src={nextArrow} alt="" className={styles.arrow} />
          );
        },
      },
    ];

  let columnsMobile: ColumnsType<getHomeworkById_getHomeworkById_students_grades> =
    [
      {
        title: () => <div className={styles.tableTitles}>ALUMNO</div>,
        dataIndex: "student_name",
        key: "student_name",
        sorter: {
          compare: (a, b) =>
            a?.student_name!.localeCompare(b?.student_name!, "es"),
          multiple: 1,
        },
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 400,
        render: (text, record) => {
          return (
            <div className={styles.userContainer}>
              <span
                className={styles.studentAvatar}
                style={{
                  background: `${resolveColorAvatar(
                    record?.student_avatar ?? "polux"
                  )}`,
                }}
              >
                <img
                  src={resolveAvatar(record?.student_avatar ?? "polux", "5")}
                  alt="avatar"
                />
              </span>
              <div className={styles.nameContainer}>
                <h1> {record.student_name}</h1>
                <div
                  className={
                    checkStatus(record.status) === "No entregada"
                      ? styles.notDelivered
                      : checkStatus(record.status) === "Completada"
                      ? styles.delivered
                      : styles.inCourse
                  }
                >
                  {checkStatus(record.status)}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>INTENTOS</h1>,
        dataIndex: "tries",
        key: "tries",
        align: "center",
        width: 100,
        render: (i, _) => {
          return !task?.tries || !_?.tries ? (
            <div className={styles.textStyles}>-</div>
          ) : (
            <div className={styles.tries}>
              {_?.tries?.toString()}/{task?.tries}
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>MEJOR</h1>,
        dataIndex: "high_score",
        key: "high_score",
        align: "center",
        render: (i, _) => {
          return (
            <div className={styles.numberText}>
              {_.high_score ? _.high_score : "0"}
            </div>
          );
        },
      },
    ];

  let columnsGPTMobile: ColumnsType<getHomeworkById_getHomeworkById_students_grades> =
    [
      {
        title: () => <div className={styles.tableTitles}>ALUMNO</div>,
        dataIndex: "student_name",
        key: "student_name",
        sorter: {
          compare: (a, b) =>
            a?.student_name!.localeCompare(b?.student_name!, "es"),
          multiple: 1,
        },
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 400,
        render: (text, record) => {
          return (
            <div className={styles.userContainer}>
              <span
                className={styles.studentAvatar}
                style={{
                  background: `${resolveColorAvatar(
                    record?.student_avatar ?? "polux"
                  )}`,
                }}
              >
                <img
                  src={resolveAvatar(record?.student_avatar ?? "polux", "5")}
                  alt="avatar"
                />
              </span>
              <div className={styles.nameContainer}>
                <h1> {record.student_name}</h1>

                <div
                  className={
                    checkStatus(record.status) === "No entregada"
                      ? styles.notDelivered
                      : checkStatus(record.status) === "Completada"
                      ? styles.delivered
                      : styles.inCourse
                  }
                >
                  {checkStatus(record.status)}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>CALIFICACIÓN</h1>,
        dataIndex: "tries",
        key: "tries",
        align: "center",
        width: 100,
        render: (i, _) => {
          return _.tries ? (
            _.high_score === null || _.high_score === undefined ? (
              <div
                className={styles.fixedStyles}
                onClick={(e) => {
                  setVisibleMessage(true);
                  e.stopPropagation();
                }}
              >
                corregir
              </div>
            ) : (_.delivered || _.high_score) &&
              (_.high_score !== null || _.high_score !== undefined) ? (
              _.high_score && _.high_score > 5 ? (
                <div className={styles.tabScoreContainer}>
                  <div
                    className={styles.tableHighScore}
                    onClick={(e) => {
                      setVisibleMessage(true);
                      e.stopPropagation();
                    }}
                  >
                    {_.high_score}
                  </div>
                </div>
              ) : (
                <div className={styles.tabScoreContainer}>
                  <div
                    className={styles.tableLowScore}
                    onClick={(e) => {
                      setVisibleMessage(true);
                      e.stopPropagation();
                    }}
                  >
                    {_.high_score}
                  </div>
                </div>
              )
            ) : (
              <div className={styles.textStyles}>-</div>
            )
          ) : (
            <div className={styles.textStyles}>-</div>
          );
        },
      },
    ];

  const filterEvidence = (
    e: ColumnsType<getHomeworkById_getHomeworkById_students_grades>
  ) => {
    // eslint-disable-next-line array-callback-return
    return e.filter((column) => {
      if (task?.needs_file_evidence) {
        return column;
      } else {
        if (column.key !== "evidence") {
          return column;
        }
      }
    });
  };

  const onRow = (record: getHomeworkById_getHomeworkById_students_grades) => {
    return {
      onClick: () => {
        if (
          !(
            (task?.lessons && task.lessons[0].lesson_type === "redaction") ||
            (task?.lessons && task.lessons[0].lesson_type === "conversation")
          )
        ) {
          if (checkStatus(record.status) === "Completada") {
            setVisibleModalStudentResponse(true);
            setUserIdSetted(record?.student_id);
            setInputNote(
              task?.students_grades?.map(() => ({
                isInputNote: false,
              })) ?? []
            );
          }
        } else {
          if (record?.delivered || record?.tries) {
            setUserIdSetted(Number(record?.student_id) ?? null);
            task?.lessons && task.lessons[0].lesson_type === "conversation"
              ? setVisibleDrawerConversation(true)
              : setVisibleDrawer(true);
          }
        }
      },
      style:
        checkStatus(record.status) !== "No entregada"
          ? { cursor: "pointer" }
          : undefined,
    };
  };

  return (
    <div className={styles.tableUser}>
      {visibleMessage ? (
        <MessageWeb setVisibleMessage={setVisibleMessage} />
      ) : (
        <Table
          columns={
            isMobile
              ? task?.lessons?.[0].lesson_type === "redaction" ||
                task?.lessons?.[0].lesson_type === "conversation"
                ? columnsGPTMobile
                : columnsMobile
              : task?.lessons?.[0].lesson_type === "redaction"
              ? filterEvidence(columnsGPT)
              : task?.lessons?.[0].lesson_type === "conversation"
              ? filterEvidence(columnsConversation)
              : filterEvidence(columns)
          }
          onRow={onRow}
          dataSource={task?.students_grades ?? []}
          pagination={false}
        />
      )}
    </div>
  );
};

export default StudentsStatusTable;
