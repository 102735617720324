import React, { useState } from "react";
import { Collapse, Select } from "antd";
import { Loader } from "../../Commons";
import { getCoursesBySubjectIdV2_getCoursesBySubjectIdV2 } from "../../../__generated__/gql-types/getCoursesBySubjectIdV2";
import styles from "./dosification.module.scss";
import UseGetLessonsByCourse from "../../../api/useGetLessonByCourse";
import { getLessonsByCourse_getLessonsByCourse } from "../../../__generated__/gql-types/getLessonsByCourse";
import { CardDosification } from "./CardDosification";
import useAuth from "../../../context/useAuth";
interface DosificationsProps {
  grade_id?: number;
  trimester: number;
  setLessonId: (id: string) => void;
  setSelectAcordion: (id: string) => void;
  setIndividualLesson?: (item: any) => void;
  showDrawer: () => void;
  setTrimester: React.Dispatch<React.SetStateAction<number>>;
  setGrade: (e: number) => void;
  coursesState: getCoursesBySubjectIdV2_getCoursesBySubjectIdV2[];
  loadingDosification: boolean;
  selectAcordion: string;
}

const { Panel } = Collapse;

export const Dosifications = ({
  grade_id,
  loadingDosification,
  coursesState,
  trimester,
  selectAcordion,
  setLessonId,
  setSelectAcordion,
  setIndividualLesson,
  showDrawer,
  setTrimester,
  setGrade,
}: DosificationsProps) => {
  const getLessonsByCourse = UseGetLessonsByCourse();
  const [CourseLessons, setCourseLessons] = useState<
    getLessonsByCourse_getLessonsByCourse[] | null
  >(null);
  const [loading, setLoading] = useState(false);
  const handleChangeGrade = (values: string[]) => {
    setGrade(Number(values[0]));
  };
  const { userToken } = useAuth();
  const handleChangeTrimester = (trimesterSelected: number) => {
    setTrimester(trimesterSelected);
  };
  const onChange = async (key: string | string[]) => {
    try {
      let lessonVideo: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonSlide: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonGuion: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonRedaccion: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonBookPage: getLessonsByCourse_getLessonsByCourse[] = [];
      typeof key === "string" && setSelectAcordion(key);
      setLoading(true);
      const lessons =
        typeof key === "string" &&
        (await getLessonsByCourse({
          getLessonsByCourseInput: {
            course_id: Number(key),
            section: "lessons",
          },
        }));
      if (lessons) {
        for (let index = 0; index < lessons.length; index++) {
          const typeLesson = lessons[index].lesson_type;
          if (typeLesson === "videolesson") {
            lessonVideo.push(lessons[index]);
          }
          if (typeLesson === "slides") {
            lessonSlide.push(lessons[index]);
          }
          if (typeLesson === "classroomguide") {
            lessonGuion.push(lessons[index]);
          }
          if (typeLesson === "book_pages") {
            lessonBookPage.push(lessons[index]);
          }
          if (typeLesson === "redaction") {
            lessonRedaccion.push(lessons[index]);
          }
        }
        const lessonOrder = lessonGuion
          .concat(lessonRedaccion)
          .concat(lessonVideo)
          .concat(lessonSlide)
          .concat(lessonBookPage);
        setCourseLessons(lessonOrder);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <Collapse
          defaultActiveKey={selectAcordion}
          accordion
          bordered={false}
          className={styles.collapseContainerNem}
          onChange={onChange}
        >
          <div className={styles.rowContainer}>
            <Select
              disabled={
                userToken?.schools[0].name === "Colegio Iluminación" ||
                userToken?.schools[0].name === "COLEGIO PROGRESO"
                  ? true
                  : false
              }
              defaultValue={grade_id ? [grade_id!.toString()] : ["6"]}
              className={styles.selectGradeNem}
              onChange={handleChangeGrade}
              options={[
                { value: "4", label: "4° Grado" },
                { value: "5", label: "5° Grado" },
                { value: "6", label: "6° Grado" },
              ]}
            />
            <div className={styles.containerTrimester}>
              <div
                className={
                  trimester === 1
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(1)}
              >
                Primer Trimestre
              </div>
              <div
                className={
                  trimester === 2
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(2)}
              >
                Segundo Trimestre
              </div>
              <div
                className={
                  trimester === 3
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(3)}
              >
                Tercer Trimestre
              </div>
            </div>
            <div className={styles.containerTrimesterMobile}>
              <div
                className={
                  trimester === 1
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(1)}
              >
                Trimestre 1
              </div>
              <div
                className={
                  trimester === 2
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(2)}
              >
                Trimestre 2
              </div>
              <div
                className={
                  trimester === 3
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(3)}
              >
                Trimestre 3
              </div>
            </div>
          </div>
          <div className={styles.tableHeaderContainer}>
            <p className={styles.courseHeader}>Curso</p>
            <p className={styles.weekHeader}>Semana SEP</p>
            <p className={styles.pagesHeader}>Páginas</p>
          </div>
          {loadingDosification ? (
            <Loader />
          ) : (
            <>
              {coursesState.map((course) => {
                return (
                  <Panel
                    key={course.id}
                    showArrow={false}
                    header={
                      <>
                        <div className={styles.panelContainer}>
                          <div className={styles.panelTitle}>
                            {course.title}
                          </div>
                          <div className={styles.panelSemana}>
                            Semana {course.week}
                          </div>
                          <div className={styles.panelSepBookPages}>
                            {course.sep_book_pages}
                          </div>
                        </div>
                        <div className={styles.panelContainerMobile}>
                          <div className={styles.containerPanelText}>
                            <div>Semana {course.week}</div>
                            <div>Págs {course.sep_book_pages}</div>
                          </div>
                          <div
                            className={
                              course.id === selectAcordion
                                ? styles.panelTitleSelect
                                : styles.panelTitle
                            }
                          >
                            {course.title}
                          </div>
                        </div>
                      </>
                    }
                    style={{
                      background: "#F3F3F6",
                      marginBottom: 10,
                      borderRadius: 12,
                      backgroundColor: "#F3F3F6",
                      fontStyle: "normal",
                      width: "auto",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "140%",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F3F3F6",
                        marginRight: -16,
                        borderRadius: 12,
                      }}
                    >
                      <div
                        className={
                          CourseLessons && CourseLessons.length > 3
                            ? styles.cardsContainer
                            : styles.cardsContainerWithoutScroll
                        }
                        style={{
                          backgroundColor: "#F3F3F6",
                          width: "100%",
                        }}
                      >
                        {loading ? (
                          <Loader />
                        ) : (
                          CourseLessons?.map((lesson, index) => {
                            return (
                              <CardDosification
                                lesson={lesson}
                                index={index}
                                showDrawer={showDrawer}
                                setLessonId={setLessonId}
                                key={lesson?.id + lesson?.title}
                                setIndividualLesson={setIndividualLesson}
                              />
                            );
                          })
                        )}
                      </div>
                    </div>
                  </Panel>
                );
              })}
            </>
          )}
        </Collapse>
      </div>
    </>
  );
};
