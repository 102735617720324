import React, { useEffect, useState } from "react";
import styles from "./headerStudentResponse.module.scss";
import closeIcon from "../../../../../../assets/icons/others/close-icon.svg";
import {
  getHomeworkById_getHomeworkById,
  getHomeworkById_getHomeworkById_students_grades,
} from "../../../../../../__generated__/gql-types/getHomeworkById";
import {
  resolveAvatar,
  resolveColorAvatar,
} from "../../../../../../utils/functions";
import { environment } from "../../../../../../configuration/environment";
import download from "../../../../../../assets/icons/teacher/download_table.svg";
import noDownloader from "../../../../../../assets/icons/teacher/noDownloader.svg";
import { checkStatus } from "../../../StudentsStatusTable/utils";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { DependencyRefresh } from "../../../../../../redux/store/store";
import { setDependencyRefresh } from "../../../../../../redux/slices/dependencyRefresh";
import useEditHomeworkEventResult from "../../../../../../api/useEditHomeworkEventResult";

interface PropsHeader {
  onCloseDrawer: () => void;
  task: getHomeworkById_getHomeworkById;
  userId: number | null;
}

const HeaderStudentResponse: React.FC<PropsHeader> = ({
  onCloseDrawer,
  task,
  userId,
}) => {
  const dispatch = useAppDispatch();
  const [isNoteInput, setIsNoteInput] = useState(false);
  const student = task?.students_grades?.find(
    (user) => user.student_id === userId
  );
  const [note, setNote] = useState(student?.high_score);
  const editHomeworkEventResult = useEditHomeworkEventResult();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const url = `${environment.gcpBucketStudentEvidence}${student?.studentEvidenceUrl}`;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !(
        (e.key >= "0" && e.key <= "9") || //numbers
        e.key === "Backspace" || //backspace
        e.key === "ArrowLeft" || //left arrow
        e.key === "ArrowRight" || //right arrow
        e.key === "Tab" || //tab
        e.key === "Enter" || //enter
        e.key === "."
      )
    ) {
      e.preventDefault();
    }
    if (e.key === "Enter") {
      const newValue =
        Number(e.currentTarget.value) < 0
          ? 0
          : Number(e.currentTarget.value) > 10
          ? 10
          : Number(e.currentTarget.value);
      setNote(newValue);
      setIsNoteInput(!isNoteInput);
      editHomeworkEventResult({
        homework_id: Number(task.id),
        new_result: newValue,
        student_id: student?.student_id,
      });
      dispatch(setDependencyRefresh(!dependencyRefresh));
    }
  };

  return (
    <div className={styles.task}>
      <div className={styles.userContainer}>
        <span
          className={styles.studentAvatar}
          style={{
            background: `${resolveColorAvatar(
              student?.student_avatar ?? "polux"
            )}`,
          }}
        >
          <img
            src={resolveAvatar(student?.student_avatar ?? "polux", "5")}
            alt="avatar"
          />
        </span>
        <h1 className={styles.nameContainer}> {student?.student_name}</h1>
        <div className={styles.deliveredContent}>
          <p
            className={
              checkStatus(student?.status!) === "No entregada"
                ? styles.notDelivered
                : checkStatus(student?.status!) === "Completada"
                ? styles.delivered
                : styles.inCourse
            }
          >
            {checkStatus(student?.status!)}
          </p>
        </div>
        <div className={styles.triesContainer}>
          {!task?.tries || !student?.tries ? (
            <div className={styles.textStyles}>-</div>
          ) : (
            <div className={styles.tries}>
              {student?.tries?.toString()}/{task?.tries}
            </div>
          )}
        </div>
        <div className={styles.tabScoreContainer}>
          {typeof note === "number" ? (
            <div
              className={styles.containerInputAndNote}
              onClick={() => {
                setIsNoteInput(true);
              }}
            >
              <input
                className={styles.boxQualification}
                type="number"
                min={0}
                max={10}
                step={1}
                defaultValue={note ?? undefined}
                style={{
                  display: isNoteInput ? "flex" : "none",
                }}
                onKeyDown={handleKeyDown}
                onInput={(e) => {
                  const target = e.target as HTMLInputElement;
                  const value = target.value;

                  //check if the value is a valid number or contains a "."
                  if (!value || value.endsWith(".")) {
                    //allow the user to temporarily type a "."
                    return;
                  }

                  //convert the value to a number and round it
                  let numericValue = parseFloat(value);

                  //ensure it is a number within the range (0-10)
                  numericValue = Math.min(
                    Math.max(Math.round(numericValue), 0),
                    10
                  );

                  //update the rounded value in the input
                  target.value = numericValue.toString();
                }}
              />
              <div
                className={
                  note > 5 ? styles.tableHighScore : styles.tableLowScore
                }
                style={{
                  display: isNoteInput ? "none" : "flex",
                }}
              >
                {note}
              </div>
            </div>
          ) : (
            <div className={styles.textStyles}>-</div>
          )}
        </div>
        <div className={styles.downloadContainer}>
          {task?.needs_file_evidence ? (
            student?.studentEvidenceUrl ? (
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                style={{ cursor: "pointer" }}
              >
                <img src={download} alt="download" />
              </a>
            ) : (
              <div className={styles.noDownloaderDisable}>
                <img src={noDownloader} alt="download" />
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles.thirdyTaskColumn}>
        <img
          src={closeIcon}
          alt=""
          className={styles.crossImg}
          onClick={onCloseDrawer}
        />
      </div>
    </div>
  );
};

export default HeaderStudentResponse;
