import { FC, useState, useEffect } from "react";
import styles from "./lessons.module.scss";
import { getLessonById_getLessonById } from "../../__generated__/gql-types/getLessonById";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import UseClassroomsByTeacher from "../../api/useGetClassroomsByTeacher";
import { Dosifications } from "./Dosification/Dosifications";
import { functionReturnIdSubject } from "./Utils";
import useAuth from "../../context/useAuth";
import { ModalError } from "../Students/Missions/NewQuizz/ModalSave";
import { DrawerLessonNew } from "../Lessons/Drawer";
import useGetCoursesForNewContent from "../../api/useGetCoursesForNewContent";
import { GetCoursesForNewContent_getCoursesForNewContent } from "../../__generated__/gql-types/GetCoursesForNewContent";
import DosificationDrawer from "../English/DosificationDrawer/DosificationDrawer";

const English: FC = () => {
  const { userToken } = useAuth();
  const schoolName = userToken?.schools[0].name;
  const location = useLocation();
  const navigate = useNavigate();
  let { parametro1, parametro2, lessonIdParams } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const gradeParams = queryParams.get("grade");
  const pageParams = queryParams.get("page");
  const weekParams = queryParams.get("week_english");
  const trimesterParams = queryParams.get("trimester");
  const courseParams = queryParams.get("course");
  // Obtener el valor de un parámetro de la consulta
  const getClassroomsByTeacher = UseClassroomsByTeacher();
  const getCoursesForNewContent = useGetCoursesForNewContent();
  const [coursesState, setCoursesState] = useState<
    GetCoursesForNewContent_getCoursesForNewContent[]
  >([]);

  const [visible, setVisible] = useState<boolean>(
    lessonIdParams ? true : false
  );

  const userGrades = userToken?.user_grade;
  let gradesSorted: number[] = [];
  if (userGrades && userGrades.length > 0) {
    userGrades.map((a) => gradesSorted.push(a));
  } else {
    gradesSorted.push(6);
  }
  gradesSorted.sort((a, b) => a - b);

  // Query Lessons States
  const [grade, setGrade] = useState<number>(
    gradeParams
      ? Number(gradeParams)
      : userToken?.role === "teacher" || userToken?.role === "coordinator"
      ? gradesSorted[0]
      : 6
  );
  const [trimester, setTrimester] = useState<number>(
    schoolName === "Colegio Nuevo Horizonte" // unicamente para demo en prod
      ? 2
      : trimesterParams
      ? Number(trimesterParams)
      : 2
  );
  const [subjectId, setSubjectId] = useState<string>(
    functionReturnIdSubject(parametro2)
  );
  const [selectAcordion, setSelectAcordion] = useState<string>(
    courseParams ?? ""
  );
  const [sepBookPage, setSepBookPage] = useState<string>(pageParams ?? "");
  const [selectedWeek, setSelectedWeek] = useState<string>(weekParams ?? "");
  // Pagination
  const [lessonId, setLessonId] = useState<string>(lessonIdParams ?? "");

  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(
      location.state?.lesson ?? null
    );

  const [loadingDosification, setLoadingDosification] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [lessonViewSelected, setLessonViewSelected] = useState<string>(
    parametro1 ?? "dosification"
  );
  const [openModalError, setOpenModalError] = useState(false);
  const onCloseModalDelete = () => {
    setOpenModalError(false);
  };

  // Individual Lesson Drawer Functions
  const showDrawer = () => {
    setVisible(true);
  };
  const showDosificationForSchool = () => {
    let showDosification = true;
    userToken?.schools.map((school) => {
      if (
        school.name === "Escuela Piloto" ||
        school.name === "Escuela Demo" ||
        school.name === "Demo en Casa" ||
        school.name === "Union"
      ) {
        showDosification = false;
      }
    });

    if (!showDosification) {
      setLessonViewSelected("search");
    }

    return showDosification;
  };
  const DrawerOnClose = () => {
    setVisibleDrawer(false);
  };
  const onClose = () => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      params.delete("key");

      return params.toString();
    });
    setVisible(false);
    setLessonId("");
    setIndividualLesson(null);
  };

  // get Curses from dosification
  useEffect(() => {
    (async () => {
      setLoadingDosification(true);
      try {
        const response = await getCoursesForNewContent({
          getCoursesForNewContentInput: {
            grade_id: grade,
            threemester_english: trimester,
            threemester_reading: null,
          },
        });
        if (response) {
          const sortcoursesEnglish = response.sort((a, b) => {
            if (a.week_english && b.week_english)
              return a.week_english - b.week_english;
            return 0;
          });
          setCoursesState(sortcoursesEnglish);
        }

        setLoadingDosification(false);
      } catch (error) {
        setOpenModalError(true);

        console.log(error);
        setLoadingDosification(false);
      }
    })();
  }, [subjectId, grade, trimester, getCoursesForNewContent]);

  // changing params
  useEffect(() => {
    const nuevaUrl =
      lessonId !== ""
        ? `/english/${lessonViewSelected}/english/${lessonId}?grade=${grade}&page=${sepBookPage}&week=${selectedWeek}&trimester=${trimester}&course=${selectAcordion}`
        : `/english/${lessonViewSelected}/english?grade=${grade}&page=${sepBookPage}&week=${selectedWeek}&trimester=${trimester}&course=${selectAcordion}`;
    navigate(nuevaUrl);
  }, [
    navigate,
    subjectId,
    lessonViewSelected,
    lessonId,
    grade,
    sepBookPage,
    selectedWeek,
    trimester,
    selectAcordion,
  ]);

  return (
    <div className={styles.containerLesson}>
      <div className={styles.leftItems}>
        {(userToken?.role === "teacher" || userToken?.role === "coordinator") &&
          showDosificationForSchool() && (
            <button
              name="Download Dosages"
              className={styles.viewAllDosifications}
              onClick={() => {
                setVisibleDrawer(true);
              }}
            >
              Download Scope & Sequence
            </button>
          )}
        <DosificationDrawer visible={visibleDrawer} onClose={DrawerOnClose} />
      </div>
      <div className={styles.filtersAndTableContainer}>
        {/* VISTA DE LECCIONES */}
        <div className={styles.tableContainer}>
          <Dosifications
            selectAcordion={selectAcordion}
            setSelectAcordion={setSelectAcordion}
            loadingDosification={loadingDosification}
            coursesState={coursesState}
            grade_id={grade === 0 ? 6 : grade}
            trimester={trimester}
            showDrawer={showDrawer}
            setTrimester={setTrimester}
            setLessonId={setLessonId}
            setIndividualLesson={setIndividualLesson}
            setGrade={setGrade}
            isDemo={schoolName === "Colegio Nuevo Horizonte"}
          />
        </div>
      </div>

      <DrawerLessonNew
        onClose={onClose}
        visible={visible}
        lessonViewSelected={lessonViewSelected}
        back={"lessons"}
        individualLesson={individualLesson}
      />
      <ModalError
        onCloseModalDelete={onCloseModalDelete}
        open={openModalError}
      ></ModalError>
    </div>
  );
};

export default English;
