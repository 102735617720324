import React from "react";
import { Link } from "react-router-dom";
import styles from "./siderMobileTeacher.module.scss";
import teacherIcon from "../../../../../assets/icons/teacher/graduationCap/graduationCap.svg";
import teacherIconSelected from "../../../../../assets/icons/teacher/graduationCap/graduationCap-selected.svg";
import bookIcon from "../../../../../assets/icons/teacher/book/book.svg";
import bookIconSelected from "../../../../../assets/icons/teacher/book/book-selected.svg";
import pointsMobile from "../../../../../assets/icons/siderStudent/test.svg";
import pointsMobileSelected from "../../../../../assets/icons/siderStudent/pointsMobileSelected.svg";

interface SiderTeacherProps {
  path: string;
  activeButton: boolean;
  setPath: (e: string) => void;
  setVisible: (e: boolean) => void;
  onClose: () => void;
}
const SiderMobileTeacher: React.FC<SiderTeacherProps> = ({
  setPath,
  onClose,
  path,
  activeButton,
  setVisible,
}) => {
  return (
    <>
      <div
        onClick={() => {
          setPath("2");
          onClose();
        }}
      >
        <Link to="/myclasses" className={styles.centerImage}>
          <div
            className={
              path === "2"
                ? styles.containerImgSelected
                : styles.containerNotImgSelected
            }
          >
            <img
              src={path === "2" ? teacherIconSelected : teacherIcon}
              alt="Mis Clases"
            />
          </div>

          <p className={path === "2" ? styles.itemSelected : styles.item}>
            Clases
          </p>
        </Link>
      </div>

      <div
        style={{ backgroundColor: "#fff" }}
        className={styles.centerImage}
        onClick={() => setVisible(true)}
      >
        <div
          className={
            activeButton
              ? styles.containerImgSelected
              : styles.containerNotImgSelected
          }
        >
          <img src={activeButton ? bookIconSelected : bookIcon} alt="La Nem" />
        </div>
        <p className={activeButton ? styles.itemSelected : styles.item}>
          Programas
        </p>
      </div>

      <Link
        to="/points"
        className={styles.centerImage}
        onClick={() => {
          setPath("10");
          setVisible(false);
        }}
      >
        <div
          className={
            path === "10"
              ? styles.containerImgSelected
              : styles.containerNotImgSelected
          }
        >
          <img
            src={path === "10" ? pointsMobileSelected : pointsMobile}
            alt="Lecciones"
          />
        </div>
        <p className={path === "10" ? styles.itemSelected : styles.item}>
          Puntos
        </p>
      </Link>
    </>
  );
};

export default SiderMobileTeacher;
