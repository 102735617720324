import React, { FC, useState } from "react";
import styles from "./panelCourses.module.scss";
import { getCoursesBySearch_getCoursesBySearch } from "../../../../__generated__/gql-types/getCoursesBySearch";
import { Collapse } from "antd";
import { getLessonById_getLessonById } from "../../../../__generated__/gql-types/getLessonById";
import { getLessonsByCourse_getLessonsByCourse } from "../../../../__generated__/gql-types/getLessonsByCourse";
import UseGetLessonsByCourse from "../../../../api/useGetLessonByCourse";
import bookSubject from "../../../../assets/icons/book_subject.svg";
import calculatorSubject from "../../../../assets/icons/calculator_subject.svg";
import scienceSubject from "../../../../assets/icons/science_subject.svg";
import englishSubject from "../../../../assets/icons/filters/inglesSelect.svg";
import { CardDosification } from "../../../Lessons/Dosification/CardDosification";
import { DrawerLessonNew } from "../../../Lessons/Drawer";
import { Loader } from "../../../Commons";
import useAuth from "../../../../context/useAuth";

interface PanelCursesProps {
  contPage: number;
  setContPage: (e: number) => void;
  courses: getCoursesBySearch_getCoursesBySearch[];
}

const PanelCourses: FC<PanelCursesProps> = ({
  courses,
  contPage,
  setContPage,
}) => {
  const { userToken } = useAuth();
  const { Panel } = Collapse;
  const getLessonsByCourse = UseGetLessonsByCourse();

  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [CourseLessons, setCourseLessons] = useState<
    getLessonsByCourse_getLessonsByCourse[] | null
  >(null);
  const [selectAcordion, setSelectAcordion] = useState<string>("");

  useState<boolean>(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const onChange = async (key: string | string[]) => {
    try {
      let lessonVideo: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonSlide: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonGuion: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonDynamics: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonBookPage: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonRedaccion: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonEvaluation: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonConversation: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonWritingExam: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonAudioExam: getLessonsByCourse_getLessonsByCourse[] = [];
      setSelectAcordion(key[0]);
      setLoading(true);
      const lessons = await getLessonsByCourse({
        getLessonsByCourseInput: {
          course_id: Number(key[0]),
          section: "searcher",
        },
      });
      if (lessons) {
        for (let index = 0; index < lessons.length; index++) {
          const typeLesson = lessons[index].lesson_type;
          if (typeLesson === "videolesson") {
            lessonVideo.push(lessons[index]);
          }
          if (typeLesson === "slides") {
            lessonSlide.push(lessons[index]);
          }
          if (
            typeLesson === "classroomguide" &&
            (userToken?.role === "teacher" || userToken?.role === "coordinator")
          ) {
            lessonGuion.push(lessons[index]);
          }
          if (
            typeLesson === "manipulative_dynamics" &&
            (userToken?.role === "teacher" || userToken?.role === "coordinator")
          ) {
            lessonDynamics.push(lessons[index]);
          }
          if (typeLesson === "book_pages") {
            lessonBookPage.push(lessons[index]);
          }
          if (
            typeLesson === "redaction" &&
            (userToken?.role === "teacher" || userToken?.role === "coordinator")
          ) {
            lessonRedaccion.push(lessons[index]);
          }
          if (typeLesson === "evaluation") {
            lessonEvaluation.push(lessons[index]);
          }
          if (typeLesson === "conversation") {
            lessonConversation.push(lessons[index]);
          }
          if (typeLesson === "writing_exam") {
            lessonWritingExam.push(lessons[index]);
          }
          if (typeLesson === "audio_exam") {
            lessonAudioExam.push(lessons[index]);
          }
        }

        const lessonOrder = lessonGuion
          .concat(lessonDynamics)
          .concat(lessonVideo)
          .concat(lessonSlide)
          .concat(lessonBookPage)
          .concat(lessonRedaccion)
          .concat(lessonConversation)
          .concat(lessonEvaluation)
          .concat(lessonWritingExam)
          .concat(lessonAudioExam);
        setCourseLessons(lessonOrder);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Collapse
      defaultActiveKey={selectAcordion}
      accordion
      bordered={false}
      className={styles.collapseContainerNem}
      onChange={onChange}
    >
      <div className={styles.tableHeaderContainer}>
        <div className={styles.firstColumn}>
          <div className={styles.panelGrade}>Grado</div>
          <div className={styles.panelSubject}>Asignatura</div>
          <div className={styles.panelTitle}>Secuencia didáctica</div>
        </div>
        <div className={styles.secondColumn}>
          <div className={styles.panelProgram}>Programa</div>
          <div className={styles.panelProgram}>Semana</div>
          <div className={styles.panelSepBookPages}>Páginas</div>
        </div>
      </div>

      {courses.map((course) => {
        return (
          <Panel
            key={course.id}
            showArrow={false}
            className={styles.panelStyles}
            header={
              <div className={styles.panelContainer}>
                <div className={styles.firstColumn}>
                  <div className={styles.columnTablet}>
                    <div className={styles.panelGrade}>{course.grade_id}°</div>
                    <div className={styles.panelSubject}>
                      {course.subject_id === 1 && (
                        <div className={styles.containerSubject}>
                          <img src={calculatorSubject} alt="default" />
                          <div>Matemáticas</div>
                        </div>
                      )}
                      {course.subject_id === 2 && (
                        <div className={styles.containerSubject}>
                          <img src={bookSubject} alt="default" />
                          <div>Español</div>
                        </div>
                      )}
                      {course.subject_id === 4 && (
                        <div className={styles.containerSubject}>
                          <img src={scienceSubject} alt="default" />
                          <div>Ciencias</div>
                        </div>
                      )}
                      {course.subject_id === 16 && (
                        <div className={styles.containerSubject}>
                          <img src={englishSubject} alt="default" />
                          <div>Inglés</div>
                        </div>
                      )}
                      {course.subject_id === 17 && (
                        <div className={styles.containerSubject}>
                          <img src={bookSubject} alt="default" />
                          <div>Gramática</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.panelTitle}>{course.title}</div>
                </div>
                <div className={styles.secondColumn}>
                  <div className={styles.panelProgram}>
                    {course.nem || course.subject_id === 16 ? "Luca" : "2017"}
                  </div>
                  <div className={styles.panelSemana}>
                    {course.nem ? course.week : course.week_2017}
                  </div>
                  <div className={styles.panelSepBookPages}>
                    {course.nem
                      ? course.sep_book_pages
                      : course.sep_book_pages_2017}
                  </div>
                </div>
              </div>
            }
          >
            <div className={styles.cardsContainer}>
              {loading ? (
                <Loader />
              ) : (
                CourseLessons?.map((lesson, index) => {
                  return (
                    <CardDosification
                      lesson={lesson}
                      index={index}
                      showDrawer={showDrawer}
                      key={lesson?.id + lesson?.title}
                      setIndividualLesson={setIndividualLesson}
                    />
                  );
                })
              )}
            </div>

            <DrawerLessonNew
              onClose={onClose}
              visible={visible}
              back={"nem"}
              individualLesson={individualLesson}
            />
          </Panel>
        );
      })}

      <div className={styles.containerButton}>
        {contPage <= courses.length && (
          <div
            className={styles.buttonStyles}
            onClick={() => {
              setContPage(contPage + 4);
            }}
          >
            Ver más
          </div>
        )}
      </div>
    </Collapse>
  );
};

export default PanelCourses;
