import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getLessonsByCourse,
  getLessonsByCourseVariables,
} from "../__generated__/gql-types/getLessonsByCourse";

const UseGetLessonsByCourse = () => {
  const client = useApolloClient();

  return useCallback(
    async (getLessonsByCourseInput: getLessonsByCourseVariables) => {
      const result: ApolloQueryResult<getLessonsByCourse> = await client.query({
        query: GET_LESSONS_BY_COURSE,
        fetchPolicy: "no-cache",
        variables: getLessonsByCourseInput,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getLessonsByCourse;
    },
    [client]
  );
};

export default UseGetLessonsByCourse;

const GET_LESSONS_BY_COURSE = gql`
  query getLessonsByCourse($getLessonsByCourseInput: GetLessonsByCourseInput) {
    getLessonsByCourse(getLessonsByCourseInput: $getLessonsByCourseInput) {
      id
      description
      title
      cover
      week
      lesson_type
      learnings
      sep_book_pages
      sep_name
      publish
      result
      grade_id
      course_id
      subject_id
      order_by
      subject {
        id
      }
      grade {
        id
        name
      }
      axes {
        id
        name
      }
      themes {
        id
        name
      }
      tags {
        id
        name
      }
      lesson_content {
        videos {
          id
          imageUrl
        }
        literacy {
          id
          cover
        }
        aiconversations {
          id
          card_image
        }
      }
    }
  }
`;
