import {
  getLessonById_getLessonById as LessonData,
  getLessonById_getLessonById_lesson_content_aiconversations_section_1 as AiconversationQuestion,
} from "../../../../../__generated__/gql-types/getLessonById";

export interface MainPromptParams {
  individualLesson: LessonData;
  questions: AiconversationQuestion[];
  contQuestion: number;
  userMessage: string;
}

export interface GoodbyePromptParams {
  individualLesson: LessonData;
  questions: AiconversationQuestion[];
  contQuestion: number;
}
export interface ErrorCorrectionPromptParams {
  userMessage: string;
}

export interface SpeakingPracticePromptParams {
  individualLesson: LessonData;
  questions: AiconversationQuestion[];
  contQuestion: number;
}
export interface SpeakingResponsePromptParams {
  individualLesson: LessonData;
  questions: AiconversationQuestion[];
  contQuestion: number;
  userMessage: string;
}

export const speakingResponsePrompt = ({
  individualLesson,
  questions,
  contQuestion,
  userMessage,
}: SpeakingResponsePromptParams): { role: string; content: string }[] => {
  if (contQuestion === questions.length) {
    return [
      {
        role: "user",
        content: `You are in the middle of an English speaking practice (i.e. in English) with a Spanish child in ${
          individualLesson.grade?.grade
        } grade at ${
          individualLesson.lesson_content.aiconversations?.level_cerf
        } CEFR level. You asked this: "${
          questions[contQuestion - 1].question
        }". Student responded this: "${userMessage}". Respond to their answer without making any questions (response must be in English), staying in character and remembering the CEFR level and age. Respond in 10 words maximum. Remember, you are pretending to be ${
          individualLesson.lesson_content.aiconversations
            ?.character_for_role_play
        }.`,
      },
    ];
  } else {
    return [
      {
        role: "user",
        content: `You are in the middle of an English speaking practice (i.e. in English) with a Spanish child in ${
          individualLesson.grade?.grade
        } grade at ${
          individualLesson.lesson_content.aiconversations?.level_cerf
        } CEFR level. You asked this: "${
          questions[contQuestion - 1].question
        }". Student responded this: "${userMessage}". ***IMPORTANT***: Respond to their answer **without asking any questions**. Respond in 10 words max (response must be in English), staying in character. Do not ask any questions within your response. Make sure your vocabulary, language, and sentence structure are appropriate to CEFR level and age. Then, ask the next question: "${
          questions[contQuestion].question
        }". Remember, you are pretending to be ${
          individualLesson.lesson_content.aiconversations
            ?.character_for_role_play
        }.`,
      },
    ];
  }
};

export const speakingPracticePrompt = ({
  individualLesson,
  questions,
  contQuestion,
}: SpeakingPracticePromptParams): { role: string; content: string } => {
  return {
    role: "assistant",
    content: `I want to give speaking English practice to a Spanish child in ${individualLesson.grade?.grade} grade at ${individualLesson.lesson_content.aiconversations?.level_cerf} CEFR level. 
    You are pretending to be ${individualLesson.lesson_content.aiconversations?.character_for_role_play}. Use appropriate language for their age and level.
    Introduce yourself in less than 10 words, transition smoothly to the next question, and ask: "${questions[contQuestion].question}"`,
  };
};
export const errorCorrectionPrompt = ({
  userMessage,
}: ErrorCorrectionPromptParams): { role: string; content: string } => {
  return {
    role: "user",
    content: `Error Correction Prompt:
1. You are reviewing chat texts written by a non-native English child.
2. IMPORTANT: Your task is to IDENTIFY AND CORRECT ALL grammar, spelling, tense, and agreement errors in the text provided, IN EVERY TEXT provided by the child.
3. DO NOT MISS ANY ERRORS. Ensure every mistake is corrected. **IMPORTANT:** Do not count capitalization errors (such as wrong use of upper or lower case) as errors and do not count missing a full stop.
4. IMPORTANT: If any words are written in Spanish, REPLACE them with their English translations and mark those corrections in green as well.
5. REQUIRED: Transform the whole text into HTML, where ONLY THE CORRECTIONS are marked in green. Follow this HTML example structure for corrections: ‘Errors:<p>She liked to go to the grocery <span style="color: green;">store</span> and buy things for the <span style="color: green;">poor</span>, she was very <span style="color: green;">generous</span>.</p>’ (It is COMPULSORY you respect the structure so the function doesn't break.)
6. **DO NOT** include any explanations or text outside of the HTML structure provided in the example.
8. Respond with only the corrected HTML version of the text, starting with "Errors:" and following the exact HTML example structure.

For this text: ${userMessage}`,
  };
};

export const goodbyePrompt = ({
  individualLesson,
  questions,
  contQuestion,
}: GoodbyePromptParams): { role: string; content: string } => {
  return {
    role: "user",
    content: `You are in the middle of English practice with a Spanish child in ${
      individualLesson.grade?.grade
    } grade at ${
      individualLesson.lesson_content.aiconversations?.level_cerf
    } CEFR level. 
    You previously asked this question: "${
      questions[contQuestion - 1].question
    }"
    Now say goodbye to the student, in your role. Make sure you only use a maximum of 20 words.`,
  };
};
export const mainPrompt = ({
  individualLesson,
  questions,
  contQuestion,
  userMessage,
}: MainPromptParams): string => {
  return `You are in the middle of English practice (you must only speak in English) with a Spanish child in ${
    individualLesson.grade?.grade
  } grade at ${
    individualLesson.lesson_content.aiconversations?.level_cerf
  } CEFR level. You previously asked this question: ${
    questions[contQuestion - 1].question
  } Their response was: "${userMessage}". Execute step by step the following:
  
            1. Understanding Check: First: Verify if the response is in English. If it is in Spanish, mark Understood[0], with Errors[NO].
              Second: If the response is in English, check if it is related to the topic. If the response is relevant (even if incomplete), mark Understood[1]. If the response has no relation to the question, mark Understood[0].
              
  
            2. Error Check: Carefully review the response for ALL grammar, spelling, tense, and vocabulary errors. Pay special attention to the verb tense used in the question ${
              questions[contQuestion - 1].question
            }. Ensure the student's response uses the correct verb tense based on the question asked.  **IMPORTANT:** Do not count capitalization errors (such as wrong use of upper or lower case) as errors and do not count missing a full stop. ONLY mark a tense as incorrect if it does not align with the tense used in the question. ENSURE NO ERRORS ARE MISSED. If there are other errors, return Errors[YES]; if there are none, return Errors[NO].
  
      3. Give the student’s answer a score out of 10. They should never receive a score lower than 5 regardless of their grader level, because that lowers their overall average score too much (even if they respond in Spanish, they should get a score of 5). Specifically, if Understood = 0, they should get 5 points.  Be generous and kind with your scoring. Remember, these are primary school students with a low ability in English.
  
              Scoring will be determined based on each student’s grade level and expected English level. Here is a general guideline for the scoring, though response lengths and content can vary based on the question asked:
  
      ${
        individualLesson.grade?.grade &&
        textGrade[
          (individualLesson.grade?.grade?.toString() as keyof typeof textGrade) ||
            "3"
        ]
      }
            Respond with the following, with nothing else. No introduction or explanation.
            Score[X]
            Understood[X]
            Errors[X]
  `;
};

export const textGrade: Record<string, string> = {
  1: `Score 10: If the response is relevant.
    Score 5: If the response is irrelevant/nonsensical or shows minimal effort to engage with the prompt.`,
  2: `Score 10: If the response is relevant and has more than three words.
    Score 8: If the response is relevant, but has two or one word.
    Score 5: If the response is irrelevant/nonsensical or shows minimal effort to engage with the prompt.`,
  3: `Score 10: If the student makes 3 or fewer spelling errors, uses 2 or fewer Spanish words, writes at least 3 words, and provides a response that is logically coherent and appropriate for A1-level (e.g., simple sentences, correct use of common vocabulary).
  Score 9: If the student makes up to 5 spelling errors, uses up to 3 Spanish words, or provides a response that is partially coherent but still demonstrates understanding of the question.
  Score 6-8: If the student exceeds 5 spelling errors, uses more than 3 Spanish words, writes fewer than 3 words, or provides a response that is incomplete or only tangentially related to the prompt.
  Score 5: If the response is very short (e.g., fewer than 2 words) or is irrelevant/nonsensical or shows minimal effort to engage with the prompt.
  `,
  4: `Score 10: If the student makes 3 or fewer spelling errors, uses 2 or fewer Spanish words, writes at least 4 words, and provides a response that is relevant and coherent at an A1 level (e.g., simple sentences, correct use of common vocabulary).
  Score 9: If the student makes up to 5 spelling errors, uses up to 3 Spanish words, or provides a response that is partially coherent or lacks some relevance but demonstrates basic comprehension.
  Score 6-8: If the student exceeds 5 spelling errors, uses more than 3 Spanish words, writes fewer than 4 words, or provides a response that is only somewhat related to the prompt.
  Score 5: If the student makes 6 or more spelling errors, uses 4 or more Spanish words, writes fewer than 3 words, and provides a response that is irrelevant or nonsensical, demonstrating minimal effort or understanding of the prompt.
  `,
  5: `Score 10: If the student makes 1-2 spelling errors, uses 1 or fewer Spanish words, writes at least 5 words if reasonable based on the question, and provides a clear and logical response using A2-level grammar and vocabulary (e.g., basic tenses, connectors like “and” or “but”).
  Score 9: If the student makes up to 4 spelling errors, uses up to 2 Spanish words, writes fewer than 5 words (if unreasonable based on the question), or provides a response that is partially coherent or misses some elements of the question but shows a good grasp of A2-level structures.
  Score 6-8: If the student exceeds 4 spelling errors, uses more than 2 Spanish words, writes fewer than 5 words (if unreasonable based on the question), or provides a response that is only loosely related to the prompt or contains significant errors in meaning.
  Score 5: If the student makes 6 or more spelling errors, uses 4 or more Spanish words, writes fewer than 3 words, or provides a response that is irrelevant, nonsensical, or demonstrates minimal effort or only partial understanding of the prompt.
  `,
  6: `Score 10: If the student makes 1-2 spelling errors, uses 1 or fewer Spanish words, writes at least 6 words (if unreasonable for the response), and provides a clear, logical, and relevant response with A2-level complexity (e.g., proper tense use, simple explanations).
  Score 9: If the student makes up to 4 spelling errors, uses up to 2 Spanish words, or provides a response that is partially coherent or slightly misses the question but still demonstrates understanding of the prompt.
  Score 6-8: If the student exceeds 4 spelling errors, uses more than 2 Spanish words, writes fewer than 6 words (if unreasonable for the response), or provides a response that is only loosely related to the question or has errors that impede meaning.
  Score 5: If the response is very short (fewer than 4 words if unreasonable for the response) or is irrelevant/nonsensical or shows minimal effort or partial comprehension of the question.
  `,
};
