interface EnvironmentType {
  apiGraphQLURL: string;
  urlImages: string;
  urlVideos: string;
  backendUrl: string;
  awsRegion: string;
  awsKey: string;
  awsSecretKey: string;
  awsBucket: string;
  gcpBucketStudentEvidence: string;
  gcpBucketPdf: string;
  awsBucketVideos: string;
}

const resolveBucketVideosByEnv = () => {
  if (
    `${process.env.REACT_APP_NODE_ENV}` === "production" ||
    `${process.env.REACT_APP_NODE_ENV}` === "prod"
  ) {
    let urlBucketImages =
      `${process.env.REACT_APP_URL_VIDEOS}` || "https://vod.app.lucaedu.com/";
    return urlBucketImages;
  } else {
    let urlBucketImages =
      `${process.env.REACT_APP_URL_VIDEOS}` ||
      "https://vod.dev-k8s.lucaedu.com/";
    return urlBucketImages;
  }
};

export const resolveEnv = () => {
  if (
    `${process.env.REACT_APP_NODE_ENV}` === "production" ||
    `${process.env.REACT_APP_NODE_ENV}` === "prod"
  ) {
    return "prod";
  } else {
    return "dev";
  }
};

export const environment: EnvironmentType = {
  apiGraphQLURL: `${process.env.REACT_APP_API_GRAPHQL_URL}` || "",
  urlImages: `${process.env.REACT_APP_URL_IMAGES}` || "",
  urlVideos: `${process.env.REACT_APP_URL_VIDEOS}` || "",
  backendUrl: `${process.env.REACT_APP_LUCAEDU_BACKEND_URL}` || "",
  awsRegion: `${process.env.REACT_APP_AWS_REGION}`,
  awsKey: `${process.env.REACT_APP_AWS_ACCESS_KEY_ID}`,
  awsSecretKey: `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`,
  awsBucket: `${process.env.REACT_APP_AWS_BUCKET_VIDEOS}`,
  gcpBucketStudentEvidence: `${process.env.REACT_APP_URL_STUDENT_EVIDENCE}` || "",
  gcpBucketPdf: `${process.env.REACT_APP_URL_PDF}` || "",
  awsBucketVideos: resolveBucketVideosByEnv(),
};


