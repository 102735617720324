import React, { useEffect, useState } from "react";
import styles from "./clasification.module.scss";
import { BannerClasification } from "./BannerClasification";
import { useParams } from "react-router-dom";
import { TableClasification } from "./TableClasification";
import UseGetLeaderBoard from "../../../api/useGetLeaderBoard";
import { getClassroomById_getClassroomById } from "../../../__generated__/gql-types/getClassroomById";
import { getDifferenceInDays } from "../../../utils/functions";
import { getInfoTournament_getInfoTournament_userInfo as PropsTournament } from "../../../__generated__/gql-types/getInfoTournament";
import { ModalPosition } from "../ModalPosition";
import { LeaderShareProps } from "../utils";

interface PropsClasification {
  classRomm: getClassroomById_getClassroomById | null;
  setTablaScore: (e: LeaderShareProps) => void;
  tablaScore: LeaderShareProps | null;
}
const ClasificationPointsSingleClass = ({
  classRomm,
  tablaScore,
  setTablaScore,
}: PropsClasification) => {
  let { id } = useParams();
  const getLeaderBoard = UseGetLeaderBoard();
  const [openModal, setOpenModal] = useState(false);
  const [userModal, setUserModal] = useState<PropsTournament>();
  const [differenceInDays, setdifferenceInDays] = useState<number>(0);

  const onCloseModalPosition = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const responseLeader = await getLeaderBoard();
        const newTable = responseLeader?.find(
          (e) =>
            e?.grade === Number(classRomm?.program?.grade?.grade) &&
            e?.group_id === Number(classRomm?.group?.id)
        );

        newTable && setTablaScore(newTable as unknown as LeaderShareProps);
      } catch (error) {
        console.log("manejar error");
      }
    })();
  }, [getLeaderBoard, id, classRomm, setTablaScore]);

  useEffect(() => {
    (async () => {
      tablaScore?.__typename === "GradeLeaderBoard"
        ? setdifferenceInDays(
            14 -
              getDifferenceInDays(
                new Date(
                  tablaScore?.last_reset_at
                    ? Number(tablaScore?.last_reset_at)
                    : ""
                )
              )
          )
        : setdifferenceInDays(0);
    })();
  }, [tablaScore]);
  return (
    <div className={styles.container}>
      {!tablaScore ||
      (tablaScore.scores?.length && tablaScore.scores[0].score === 0) ? (
        <>
          <BannerClasification
            tablaScore={
              tablaScore?.scores?.length && tablaScore.scores[0].score === 0
                ? null
                : tablaScore
            }
            setUserModal={setUserModal}
            setOpenModal={setOpenModal}
          />
          <div className={styles.comingSoonImgContainer}>
            <div className={styles.comingSoonInnerText}>
              Ningún alumno ha conseguido puntos en este periodo de torneo
              quincenal.
              <br />
              <br /> Para poder iniciar el torneo asigna tareas a tus alumnos
              para que puedan ganar puntos y competir entre ellos.
              <br />
              <br />
              También puedes utilizar la herramienta puntos en clase para
              entregar puntos a tus alumnos.
            </div>
          </div>
        </>
      ) : (
        <>
          <BannerClasification
            tablaScore={tablaScore}
            daysLeft={Number(differenceInDays)}
            setUserModal={setUserModal}
            setOpenModal={setOpenModal}
          />
          <TableClasification
            tablaScore={tablaScore}
            setUserModal={setUserModal}
            setOpenModal={setOpenModal}
          ></TableClasification>
          {openModal && (
            <ModalPosition
              onCloseModalPosition={onCloseModalPosition}
              openModal={openModal}
              userModal={userModal}
            ></ModalPosition>
          )}
        </>
      )}
    </div>
  );
};

export default ClasificationPointsSingleClass;
