import { Drawer } from "antd";
import { Link } from "react-router-dom";
import styles from "./drawerHomeworkStudent.module.scss";
import cross from "../../../../assets/icons/others/close-icon.svg";
import downloader from "../../../../assets/icons/downloadIcon.svg";
import upload from "../../../../assets/icons/downloadDrawerLesson.svg";
import calendar from "../../../../assets/icons/calendar-icon.svg";
import arrow from "../../../../assets/icons/arrow_back.svg";
import moon from "../../../../assets/icons/students/moon.svg";
import marte from "../../../../assets/icons/students/marte.svg";
import useIsMobile from "../../../../hooks/useIsMobile";
import UseGetHomeworkByIdForStudent from "../../../../api/useGetHomeworkByIdForStudent";
import { toLocalDate } from "../../../../utils/functions";
import { FC, useEffect, useState } from "react";
import { ButtonHomeworkEvidence } from "./ButtonHomeworkEvidence";
import { Evidence } from "../SingleCourse/BannerCards/Card/DrawerInstructions/Evidence";
import { ProgressHomework } from "./ProgressHomework";
import { environment } from "../../../../configuration/environment";
import { getHomeworkByIdForStudent_getHomeworkByIdForStudent } from "../../../../__generated__/gql-types/getHomeworkByIdForStudent";
import { Loader } from "../../../Commons";
import { DrawerInteligentStudent } from "../DrawerInteligentStudent";
import { functionReturnImg } from "./utils";
import {
  evaluateNumberType,
  functionReturnTextCTA,
} from "../SingleCourse/BannerCards/Card/utils";
import { CTAButtons } from "../../../../__generated__/gql-types/globalTypes";

interface DrawerProp {
  onCloseLesson: () => void;
  visibleLesson: boolean;
  homework_id: string | undefined;
  finishedLesson?: boolean;
}

//REVISAR ESTO CUANDO VUELVAN LOS PUNTOS EXTRA
const DrawerHomeworkStudent: FC<DrawerProp> = ({
  onCloseLesson,
  visibleLesson,
  homework_id,
  finishedLesson,
}) => {
  const isMobile = useIsMobile();
  const getHomeworkByIdForStudent = UseGetHomeworkByIdForStudent();
  const [nameFile, setNameFile] = useState<string | null>(null);
  const [isVisbleDrawer, SetIsVisbleDrawer] = useState(false);
  const [panel, setPanel] = useState(finishedLesson ? "evidence" : "task");
  const [homework, setHomework] =
    useState<getHomeworkByIdForStudent_getHomeworkByIdForStudent | null>(null);

  const maxResult = Math.max(
    homework?.result ? homework.result : -1,
    homework?.post_homework_result ? homework.post_homework_result : -1
  );

  const isRedaction =
    homework?.lessons?.[0].lesson_content.redaction?.id ?? null;

  const ctaIsSeeResults = homework?.cta_button === CTAButtons.VER_RESULTADOS;

  const englishLesson = homework?.lessons?.[0].subject_id === 16;
  const subject_id = homework?.lessons?.[0].subject_id;

  const resolveDownload = () => {
    return homework?.teacher_file_url !== null
      ? `${environment.gcpBucketStudentEvidence}/${homework?.teacher_file_url}`
      : "";
  };

  useEffect(() => {
    (async () => {
      if (homework_id) {
        const response = await getHomeworkByIdForStudent({
          homeworkId: homework_id,
        });
        if (response) {
          setHomework(response);
          response.studentEvidenceEvent &&
            setNameFile(response.studentEvidenceEvent);
        }
      }
    })();
  }, [homework_id, getHomeworkByIdForStudent]);

  return (
    <>
      <Drawer
        closable={false}
        placement={isMobile ? "bottom" : "right"}
        className={finishedLesson ? "estilosIntruc" : ""}
        styles={{
          mask: finishedLesson
            ? {
                width: "100%",
                left: 0,
              }
            : {},
        }}
        onClose={() => {
          onCloseLesson();
        }}
        open={visibleLesson}
        title={
          homework && (
            <div className={styles.lessonDetailContainer}>
              <div className={styles.foto}>
                <img src={functionReturnImg(homework)} alt="default" />
              </div>

              <div className={styles.bandera}>
                <div className={styles.closeHeader}>
                  <img
                    src={
                      finishedLesson ? cross : panel === "task" ? cross : arrow
                    }
                    onClick={() => {
                      finishedLesson
                        ? onCloseLesson()
                        : panel === "task"
                        ? onCloseLesson()
                        : setPanel("task");
                    }}
                    alt="cross"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className={styles.titleContainer}>
                  <span className={styles.textTitle}>
                    {homework?.lessons && homework.lessons[0]?.title}
                  </span>
                  <p>{homework?.lessons && homework.lessons[0]?.description}</p>
                </div>
              </div>
            </div>
          )
        }
        footer={
          homework && (
            <div className={styles.footerDetail}>
              <div className={styles.containerImage}>
                <div className={styles.containerImageEvidence}>
                  <img src={moon} alt="" />
                  <img src={marte} alt="" />
                </div>
                {panel === "evidence" && !homework?.studentEvidenceEvent && (
                  <div className={styles.textEvidence}>
                    Adjunta una evidencia de la actividad requerida por tu
                    profesor.{" "}
                  </div>
                )}
              </div>
              {panel === "evidence" && (
                <ButtonHomeworkEvidence
                  homework={homework ?? null}
                  nameFile={nameFile}
                  setNameFile={setNameFile}
                />
              )}
              {panel === "task" && (
                <div className={styles.containerButtons}>
                  {homework?.needs_file_evidence &&
                    !homework.studentEvidenceEvent &&
                    homework.status !== "finished" && (
                      <button
                        className={styles.taskButtonDelete}
                        onClick={() => setPanel("evidence")}
                      >
                        {englishLesson
                          ? "UPLOAD EVIDENCE"
                          : "ADJUNTAR EVIDENCIA"}
                        <img src={upload} alt="downloader" />
                      </button>
                    )}
                  {homework?.needs_file_evidence &&
                    homework.studentEvidenceEvent && (
                      <button
                        className={styles.taskButtonDelete}
                        onClick={() => setPanel("evidence")}
                      >
                        {englishLesson ? "SEE EVIDENCE" : "VER EVIDENCIA"}
                        <img src={downloader} alt="downloader" />
                      </button>
                    )}
                  {homework?.needs_file_evidence &&
                    homework.status === "finished" &&
                    !homework.studentEvidenceEvent && (
                      <button className={styles.taskButtonNotEvidence}>
                        {englishLesson ? "NOT EVIDENCE" : "NO HAY EVIDENCIA"}
                        <img src={downloader} alt="downloader" />
                      </button>
                    )}

                  {homework.lessons &&
                  homework?.lessons[0].lesson_type === "redaction" ? (
                    homework.cta_button === CTAButtons.COMENZAR ? (
                      <Link
                        className={styles.taskButtonMision}
                        to={`/task/${
                          homework?.lessons && homework.lessons[0]?.id
                        }?homework=${homework?.id}`}
                        state={{
                          homework,
                          origin: "homework",
                          from: "homework",
                          onNavigate: true,
                        }}
                      >
                        {englishLesson ? "START" : "COMENZAR"}
                      </Link>
                    ) : homework.cta_button === CTAButtons.TERMINAR ? (
                      <Link
                        className={styles.taskButtonMision}
                        to={`/task/${
                          homework?.lessons && homework.lessons[0]?.id
                        }?homework=${homework?.id}`}
                        state={{
                          homework,
                          from: "homework",
                          origin: "homework",
                          onNavigate: true,
                        }}
                      >
                        {englishLesson ? "FINISH" : "TERMINAR"}
                      </Link>
                    ) : (
                      <div
                        className={styles.taskButtonMision}
                        onClick={() => {
                          SetIsVisbleDrawer(true);
                        }}
                      >
                        {homework.cta_button === CTAButtons.TERMINAREVIDENCIA
                          ? "TERMINAR"
                          : functionReturnTextCTA(
                              homework.cta_button as string,
                              subject_id!
                            )}
                      </div>
                    )
                  ) : homework.lessons &&
                    homework?.lessons[0].lesson_type === "conversation" ? (
                    <Link
                      className={styles.taskButtonMision}
                      to={`/task/${
                        homework?.lessons && homework.lessons[0]?.id
                      }?homework=${homework?.id}`}
                      state={{
                        homework,
                        from: "homework",
                        origin: "homework",
                        onNavigate: true,
                      }}
                    >
                      {homework?.result === null ? "START" : "REPEAT"}
                    </Link>
                  ) : (
                    <Link
                      className={styles.taskButtonMision}
                      to={`/task/${
                        homework?.lessons && homework.lessons[0]?.id
                      }?homework=${homework?.id}`}
                      state={{
                        homework,
                        from: "homework",
                        origin: "homework",
                        onNavigate: true,
                      }}
                    >
                      {!homework?.needs_file_evidence
                        ? homework?.result === null
                          ? "COMENZAR"
                          : "REPETIR"
                        : homework?.result === null
                        ? homework?.studentEvidenceEvent
                          ? "CONTINUAR"
                          : "COMENZAR"
                        : homework?.studentEvidenceEvent
                        ? "REPETIR"
                        : "CONTINUAR"}
                    </Link>
                  )}
                </div>
              )}
            </div>
          )
        }
      >
        {!homework ? (
          <Loader />
        ) : (
          <div className={styles.container}>
            {panel === "task" && (
              <>
                <div className={styles.textInstrucciones}>INSTRUCCIONES</div>
                <div className={styles.descriptionLesson}>
                  <div className={styles.containerBadge}>
                    {homework?.status === "finished" ? (
                      <div className={styles.containerFinished}>
                        <div className={styles.tagsGradeWithOutTry}>
                          <img src={calendar} alt="img" />
                          Finalizada
                        </div>
                        {!(
                          homework.lessons &&
                          homework.lessons[0].lesson_type === "redaction"
                        ) && (
                          <div className={styles.textRequiereEvidence}>
                            El plazo de entrega de la tarea ha finalizado.
                            Puedes volver a hacerla las veces que quieras para
                            conseguir todos los cristales, pero el resultado no
                            impactará en tu promedio.
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div className={styles.tagsGrade}>
                          <img src={calendar} alt="" />
                          Finaliza {homework && toLocalDate(homework.end_date)}
                        </div>

                        {isRedaction && ctaIsSeeResults && (
                          <>
                            {!homework.result && (
                              <div className={styles.redactionInfo}>
                                La tarea se ha enviado correctamente.
                              </div>
                            )}
                            {homework.redaction_homework_autocorrect ? (
                              evaluateNumberType(homework.result) ? (
                                <>
                                  <div
                                    className={styles.redactionInfoDescription}
                                  >
                                    Nuestra IA ha revisado tu tarea. Puedes ver
                                    sus correcciones clicando en el botón VER
                                    RESULTADOS.
                                  </div>
                                </>
                              ) : (
                                <div
                                  className={styles.redactionInfoDescription}
                                >
                                  Nuestra IA revisará tu misión en unos minutos.
                                  Revisa el estado de la corrección clicando en
                                  el botón VER RESULTADOS.
                                </div>
                              )
                            ) : evaluateNumberType(homework.result) ? (
                              <>
                                <div
                                  className={styles.redactionInfoDescription}
                                >
                                  El maestro ha revisado tu tarea. Puedes ver
                                  sus correcciones clicando en el botón VER
                                  RESULTADOS.
                                </div>
                              </>
                            ) : (
                              <div className={styles.redactionInfoDescription}>
                                El maestro revisará tu misión, y recibirás la
                                corrección en cuanto esté lista. Revisa el
                                estado de la corrección clicando en el botón VER
                                RESULTADOS.
                              </div>
                            )}
                          </>
                        )}

                        {homework?.tries &&
                        homework.studentTries &&
                        (homework.tries - Number(homework.studentTries) === 0 ||
                          homework.tries - Number(homework.studentTries) <
                            0) ? (
                          <div className={styles.tagsGradeWithOutTry}>
                            No te quedan intentos
                          </div>
                        ) : (
                          homework?.tries && (
                            <div className={styles.tagsGrade}>
                              Te quedan{" "}
                              {homework?.tries - Number(homework.studentTries)}{" "}
                              intentos
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>

                  <div className={styles.containerRequiereEvidence}>
                    {homework?.status !== "finished" &&
                      homework?.tries &&
                      homework.studentTries &&
                      homework.tries - Number(homework.studentTries) === 0 && (
                        <div className={styles.textRequiereEvidence}>
                          No te quedan intentos. Puedes volver a realizar la
                          misión las veces que quieras, pero el resultado no
                          impactará en tu promedio.
                        </div>
                      )}
                    {homework?.description && (
                      <div className={styles.textRequiereEvidence}>
                        {homework?.description}
                      </div>
                    )}
                    {homework?.needs_file_evidence && (
                      <div>
                        <div className={styles.titleRequiereEvidence}>
                          Requiere evidencia
                        </div>
                        <div className={styles.textRequiereEvidence}>
                          Adjunta el archivo que solicita tu profesor para
                          completar la tarea
                        </div>
                      </div>
                    )}
                    {homework?.teacher_file_url &&
                      homework?.status !== "finished" && (
                        <div>
                          <div className={styles.textRequiereEvidence}>
                            Descarga el documento que te ha enviado tu profesor.
                          </div>

                          <div className={styles.link}>
                            <a
                              href={resolveDownload()}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>Descargar archivo</span>
                            </a>
                            <img src={downloader} alt="" />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <ProgressHomework
                  resultHomework={maxResult !== -1 ? maxResult : 0}
                ></ProgressHomework>
              </>
            )}

            {panel === "evidence" && homework && (
              <Evidence homework={homework} nameFile={nameFile} />
            )}
          </div>
        )}
      </Drawer>
      {isVisbleDrawer && (
        <DrawerInteligentStudent
          onCloseDrawer={onCloseLesson}
          homework={homework}
          visibleDrawer={isVisbleDrawer}
        />
      )}
    </>
  );
};

export default DrawerHomeworkStudent;
