import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import type { MenuProps } from "antd";
import styles from "./sider.module.scss";
import useAuth from "../../../context/useAuth";
import { Link, useLocation } from "react-router-dom";

// Icons
import image from "../../../assets/icons/logo_luca.svg";
import classIcon from "../../../assets/icons/siderNews/clases_off.png";
import classSelect from "../../../assets/icons/siderNews/clases_on.png";
import lupa from "../../../assets/icons/siderNews/buscador_on.png";
import lupaoff from "../../../assets/icons/siderNews/buscador_off.png";
import projectOFF from "../../../assets/icons/siderNews/proyectos_off.png";
import projectON from "../../../assets/icons/siderNews/proyectos_on.png";
import points from "../../../assets/icons/siderNews/puntos_off.png";
import pointsSelect from "../../../assets/icons/siderNews/puntos_on.png";
import literacyOn from "../../../assets/icons/siderNews/literacy_on.png";
import literacyOff from "../../../assets/icons/siderNews/literacy_off.png";
import readingSelect from "../../../assets/icons/siderNews/lectura_on.png";
import reading from "../../../assets/icons/siderNews/lectura_off.png";
import lessonSelect from "../../../assets/icons/siderNews/lecciones_on.png";
import lesson from "../../../assets/icons/siderNews/lecciones_off.png";
import inglesOFF from "../../../assets/icons/siderNews/ingles_off.png";
import inglesON from "../../../assets/icons/siderNews/ingles_on.png";

///
import avatarOn from "../../../assets/icons/siderStudent/avatar_on.png";
import avatarOff from "../../../assets/icons/siderStudent/avatar_off.png";
import leccionOff from "../../../assets/icons/siderStudent/leccion_off.png";
import leccionOn from "../../../assets/icons/siderStudent/leccion_on.png";
import misionesOff from "../../../assets/icons/siderStudent/misiones_off.png";
import misionesOn from "../../../assets/icons/siderStudent/misiones_on.png";
////
/////

import { SiderMobile } from "../../Mobile/Layout/Sider";
import useTeacherNavigateSider from "../../../api/useTeacherNavigateSider";
import { getActualVersion } from "../../../utils/version";
import { ModalFreemiun } from "../../Shared/ModalFreemiun";
import { getUserByEmail_getUserByEmail } from "../../../__generated__/gql-types/getUserByEmail";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: React.ReactNode
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

interface SiderProps {
  userDb: getUserByEmail_getUserByEmail | null | undefined;
  isMobile: boolean;
}

const SiderLuca = ({ userDb, isMobile }: SiderProps) => {
  const { logout } = useAuth();
  const url = window.location.href;
  const viewLiteracy = userDb?.schools[0].view_literacy;
  const viewEnglish = userDb?.schools[0].view_english;
  const view_project_generator = userDb?.schools[0].view_project_generator;
  const studentViewNem = userDb?.schools[0].view_NEM_student;
  const studentView2017 = userDb?.schools[0].view_2017_student;
  const studentView2526 = userDb?.schools[0].view_2526_student; //cambiar aca
  const view_2526 = userDb?.schools[0].view_2526; //cambiar aca
  const teacherViewNEM = userDb?.schools[0].view_NEM;
  const studentViewLiteracy =
    userDb?.schools[0]?.view_library_english ||
    userDb?.schools[0]?.view_library_spanish;
  const teacherViewLiteracy =
    userDb?.schools[0]?.view_reading_spanish ||
    userDb?.schools[0]?.view_reading_english;
  const spanishLanguage = userDb?.visible_spanish_language;
  const englishLanguage = userDb?.visible_english_language;
  const studentShop = userDb?.schools[0].view_shop;
  const [itemsTeacher, setItemsTeacher] = useState<MenuItem[]>([]);
  const [itemsStudent, setItemsStudent] = useState<MenuItem[]>([]);
  const flags = useFlags();
  const ldClient = useLDClient();
  const location = useLocation();
  const teacherNavigateLog = useTeacherNavigateSider();
  const [isModalFreemiun, setIsModalFreemiun] = useState(false);
  const [versions, setversions] = useState({
    backend: "",
    front: "",
  });
  const [path, setPath] = useState<string>("");

  useEffect(() => {
    const verifyVersion = async () => {
      try {
        const actualVersion = await getActualVersion();
        actualVersion && setversions(actualVersion);
      } catch (error) {
        console.log("verify version: ", error);
      }
    };
    verifyVersion();
  }, []);

  useEffect(() => {
    if (
      userDb?.schools[0].name === "Escuela Demo Prof" &&
      (location.pathname?.includes("myclasses") ||
        location.pathname?.includes("calendar") ||
        location.pathname?.includes("points"))
    ) {
      setIsModalFreemiun(true);
    } else {
      setIsModalFreemiun(false);
    }
  }, [url, userDb, location.pathname]);

  const itemsStudentDefault: MenuItem[] = [
    getItem(
      "Mis misiones",
      "6",
      <Link to="/mymissions">
        <img src={path === "6" ? misionesOn : misionesOff} alt="Mis misiones" />
      </Link>
    ),
    getItem(
      "Cursos Luca",
      "11",
      <Link to="/nem">
        <img src={path === "11" ? leccionOn : leccionOff} alt="NEM" />
      </Link>
    ),
    getItem(
      "Cursos 2017",
      "13",
      <Link to="/2017">
        <img src={path === "13" ? leccionOn : leccionOff} alt="2017" />
      </Link>
    ),
    getItem(
      "Programa 25/26",
      "18",
      <Link to="/2526" onClick={async () => teacherNavigateLog({ action: 4 })}>
        <img
          src={path === "18" ? lessonSelect : lesson}
          alt="2526"
          className={styles.imageSider}
        />
      </Link>
    ),
    getItem(
      "Biblioteca",
      "24",
      <Link to="/literacy">
        <img src={path === "24" ? literacyOn : literacyOff} alt="reading" />
      </Link>
    ),

    getItem(
      "Mi avatar",
      "19",
      <Link to="/store">
        <img src={path === "19" ? avatarOn : avatarOff} alt="2425" />
      </Link>
    ),
  ];

  const itemsTeacherDefault: MenuItem[] = [
    getItem(
      "Mis clases",
      "2",
      <Link
        to="/myclasses"
        onClick={async () => await teacherNavigateLog({ action: 1 })}
      >
        <img
          src={path === "2" || path === "4" ? classSelect : classIcon}
          alt="Mis clases"
          className={styles.imageSider}
        />
      </Link>
    ),
    getItem(
      "Buscador",
      "12",
      <Link
        to="/searcher"
        onClick={async () => teacherNavigateLog({ action: 2 })}
      >
        <img
          src={path === "12" ? lupa : lupaoff}
          alt="Lecciones"
          className={styles.imageSider}
        />
      </Link>
    ),
    getItem(
      "Programa Luca",
      "11",
      <Link to="/nem" onClick={async () => teacherNavigateLog({ action: 3 })}>
        <img
          src={path === "11" ? lessonSelect : lesson}
          alt="NEM"
          className={styles.imageSider}
        />
      </Link>
    ),
    getItem(
      "Programa 25/26",
      "18",
      <Link to="/2526" onClick={async () => teacherNavigateLog({ action: 4 })}>
        <img
          src={path === "18" ? lessonSelect : lesson}
          alt="2526"
          className={styles.imageSider}
        />
      </Link>
    ),

    getItem(
      "Programa 2017",
      "13",
      <Link to="/2017" onClick={async () => teacherNavigateLog({ action: 4 })}>
        <img
          src={path === "13" ? lessonSelect : lesson}
          alt="2017"
          className={styles.imageSider}
        />
      </Link>
    ),

    getItem(
      "Lecturas",
      "24",
      <Link
        to="/literacy"
        onClick={async () => teacherNavigateLog({ action: 4 })}
      >
        <img
          src={path === "24" ? readingSelect : reading}
          alt="reading"
          className={styles.imageSider}
        />
      </Link>
    ),
    getItem(
      "Inglés",
      "15",
      <Link
        to="/english"
        onClick={async () => teacherNavigateLog({ action: 4 })}
      >
        <img
          src={path === "15" ? inglesON : inglesOFF}
          alt="english"
          className={styles.imageSider}
        />
      </Link>
    ),

    getItem(
      "Proyectos",
      "16",
      <Link to="/newProject">
        <img
          src={path === "16" ? projectON : projectOFF}
          alt="Puntos"
          className={styles.imageSider}
        />
      </Link>
    ),

    getItem(
      "Puntos",
      "10",
      <Link to="/points">
        <img
          src={path === "10" ? pointsSelect : points}
          alt="Puntos"
          className={styles.imageSider}
        />
      </Link>
    ),
  ];

  useEffect(() => {
    (async () => {
      setItemsTeacher(itemsTeacherDefault);
      setItemsStudent(itemsStudentDefault);
      if (Number(userDb?.id) !== 8143) {
        if (flags.mostrarSecciones) {
          //teacher
          if (!viewEnglish) {
            setItemsTeacher((prevItems) =>
              prevItems.filter((item) => item && item.key !== "15")
            );
          } else {
            if (!englishLanguage) {
              setItemsTeacher((prevItems) =>
                prevItems.filter((item) => item && item.key !== "15")
              );
            }
          }
          if (!view_project_generator) {
            setItemsTeacher((prevItems) =>
              prevItems.filter((item) => item && item.key !== "16")
            );
          } else {
            if (englishLanguage && !spanishLanguage) {
              setItemsTeacher((prevItems) =>
                prevItems.filter((item) => item && item.key !== "16")
              );
            }
          }
          if (!teacherViewLiteracy) {
            setItemsTeacher((prevItems) =>
              prevItems.filter((item) => item && item.key !== "24")
            );
          }
          if (englishLanguage && !spanishLanguage) {
            setItemsTeacher((prevItems) =>
              prevItems.filter((item) => item && item.key !== "11")
            );
          }
          if (englishLanguage && !spanishLanguage) {
            setItemsTeacher((prevItems) =>
              prevItems.filter((item) => item && item.key !== "13")
            );
          }
          if (!teacherViewNEM) {
            setItemsTeacher((prevItems) =>
              prevItems.filter((item) => item && item.key !== "11")
            );
          }

          //student
          if (!studentShop) {
            setItemsStudent((prevItems) =>
              prevItems.filter((item) => item && item.key !== "19")
            );
          }

          if (!studentView2017) {
            setItemsStudent((prevItems) =>
              prevItems.filter((item) => item && item.key !== "13")
            );
          }
          if (!studentViewNem) {
            setItemsStudent((prevItems) =>
              prevItems.filter((item) => item && item.key !== "11")
            );
          }
          if (!view_2526) {
            setItemsTeacher((prevItems) =>
              prevItems.filter((item) => item && item.key !== "18")
            );
          }
          if (!studentView2526) {
            setItemsStudent((prevItems) =>
              prevItems.filter((item) => item && item.key !== "18")
            );
          }
          if (!studentViewLiteracy) {
            setItemsStudent((prevItems) =>
              prevItems.filter((item) => item && item.key !== "24")
            );
          }
        }

        if (userDb?.role === "teacher" || userDb?.role === "coordinator") {
          location.pathname.includes("/myclasses") && setPath("2");
          location.pathname.includes("/lessons") && setPath("3");
          location.pathname.includes("/calendar") && setPath("2");
          location.pathname.includes("/means") && setPath("5");
          location.pathname.includes("/points") && setPath("10");
          location.pathname.includes("/nem") && setPath("11");
          location.pathname.includes("/searcher") && setPath("12");
          location.pathname.includes("/help") && setPath("9");
          location.pathname.includes("/newProject") && setPath("16");
          location.pathname.includes("/2017") && setPath("13");
          location.pathname.includes("/2526") && setPath("18");
        }
        viewEnglish && location.pathname.includes("/english") && setPath("15");
      }
      if (userDb?.role === "student") {
        location.pathname.includes("/store") && setPath("23");
        location.pathname.includes("/mymissions") && setPath("6");
        location.pathname.includes("/extrapoints") && setPath("7");
        location.pathname.includes("/myachievements") && setPath("8");
        location.pathname.includes("/store") && setPath("19");
        location.pathname.includes("/means") && setPath("5");
        location.pathname.includes("/2526") && setPath("18");
        studentView2017 && location.pathname.includes("/2017") && setPath("13");
        studentViewNem && location.pathname.includes("/nem") && setPath("11");

        studentViewLiteracy &&
          location.pathname.includes("/literacy") &&
          setPath("24");
      } else {
        setItemsTeacher((prevItems) =>
          prevItems.filter((item) => item && item.key !== "17")
        );
      }
    })();
  }, [
    location.pathname,
    userDb,
    viewLiteracy,
    viewEnglish,
    studentView2017,
    studentView2526,
    teacherViewLiteracy,
    englishLanguage,
    spanishLanguage,
    studentViewLiteracy,
    studentViewNem,
    flags.mostrarSecciones,
  ]);

  const handleMenus: MenuProps["onClick"] = (e) => {
    setPath(e.key);
  };
  useEffect(() => {
    ldClient && ldClient.identify({ key: "context-key-123abc" });
  }, []);

  return isMobile ? (
    <SiderMobile path={path} setPath={setPath} userDb={userDb} />
  ) : (
    <Sider className={styles.sider}>
      <img src={image} alt="Luca Logo" className={styles.image} />
      <Menu
        mode="inline"
        items={userDb?.role === "student" ? itemsStudent : itemsTeacher}
        onClick={handleMenus}
        className={styles.menu}
        selectedKeys={[`${path}`]}
        overflowedIndicator
        style={{ position: "relative" }}
      />
      {userDb?.role === "student" && (
        <div className={styles.version}>
          Local: {versions.front} <br /> Global: {versions.backend}
        </div>
      )}
      {isModalFreemiun && (
        <>
          <div className={styles.botonLogout} onClick={logout}>
            Salir
          </div>
          <ModalFreemiun open={isModalFreemiun}></ModalFreemiun>
        </>
      )}
    </Sider>
  );
};

export default SiderLuca;
