import styles from "./pointsSingleClass.module.scss";
import UseGetClassroomById from "../../api/useGetClassroomById";
import { Header } from "../Layout/Header";
import { HeaderPoints } from "./HeaderPoints";
import { SpinnerMissions } from "../Students/Missions/SpinnerMissions";
import { LeaderShareProps } from "./utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PointerPointsSingleClass } from "./PointerPointsSingleClass";
import { ClasificationPointsSingleClass } from "./ClasificationPointsSingleClass";
import { getClassroomById_getClassroomById as ClasroomProps } from "../../__generated__/gql-types/getClassroomById";

const PointsSingleClass = () => {
  const navigate = useNavigate();
  const getClassroomById = UseGetClassroomById();
  let { id, tab } = useParams();
  const [tabState, setTabState] = useState("pointer");
  const [isLoading, setIsLoading] = useState(false);
  const [classRoom, setClassRoom] = useState<ClasroomProps | null>(null);
  const [tablaScore, setTablaScore] = useState<LeaderShareProps | null>(null);
  // changing params
  useEffect(() => {
    const nuevaUrl = `/points/${id}/${tabState}`;
    navigate(nuevaUrl);
  }, [tabState, navigate, id]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (id) {
          const responseClassroom = await getClassroomById({
            classroomId: id,
          });
          setClassRoom(responseClassroom);
        }
      } catch (error) {}
      setIsLoading(false);
    })();
  }, [id, getClassroomById, setClassRoom]);
  return (
    <div className={styles.container}>
      {isLoading ? (
        <SpinnerMissions />
      ) : (
        <>
          <Header
            title={`${classRoom?.program?.grade?.grade}° ${classRoom?.group?.name}`}
            headerWithOutMobile={true}
            back={"back_points"}
          />
          <HeaderPoints
            setTabState={setTabState}
            tabState={tabState}
            classRomm={classRoom}
            setTablaScore={setTablaScore}
          />
          {tab === "pointer" ? (
            <PointerPointsSingleClass classRomm={classRoom} />
          ) : (
            <ClasificationPointsSingleClass
              classRomm={classRoom}
              tablaScore={tablaScore}
              setTablaScore={setTablaScore}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PointsSingleClass;
