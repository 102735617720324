import React, { FC } from "react";
import styles from "./messageWeb.module.scss";
import cross from "../../../../../assets/icons/close.svg";
interface MessageWebProps {
  setVisibleMessage: (e: boolean) => void;
}
const MessageWeb: FC<MessageWebProps> = ({ setVisibleMessage }) => {
  return (
    <div className={styles.containerMessage}>
      <div
        className={styles.crossMessage}
        onClick={() => {
          setVisibleMessage(false);
        }}
      >
        <img src={cross} alt="cross" />
      </div>
      Accede a la plataforma web para ver las respuestas de tus alumnos a la
      actividad, y realizar las correcciones que sean necesarias.
    </div>
  );
};

export default MessageWeb;
