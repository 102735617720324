import { Checkbox, Collapse, Drawer, List } from "antd";
import styles from "./dosificationDrawer.module.scss";
import closeIcon from "../../../../assets/icons/others/close-icon.svg";
import downarrow from "../../../../assets/icons/downArrow.svg";
import uparrow from "../../../../assets/icons/uparrow.svg";
import arrowback from "../../../../assets/icons/arrow_back.svg";
import { useState } from "react";
import {
  NotificationPositive,
  notificationNegative,
} from "../../../Shared/Notifacation/NotificationLuca";
import axios from "axios";
import { ActivityIndicator } from "../../../ActivityIndicator/ActivityIndicator";
import useIsMobile from "../../../../hooks/useIsMobile";
interface PropsFilter {
  onClose: () => void;
  visible: boolean;
}
const { Panel } = Collapse;
const dosificationCategories = [
  {
    name: "Lenguajes",
    pdfs: [
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/1o%20Grado%20-%20Lenguajes%20(NEM).pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/2o%20Grado%20-%20Lenguajes%20(NEM).pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/3o%20Grado%20-%20Lenguajes%20(NEM).pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/4o%20Grado%20-%20Lenguajes%20(NEM).pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/5o%20Grado%20-%20Lenguajes%20(NEM).pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/6o%20Grado%20-%20Lenguajes%20(NEM)2.pdf",
    ],
  },
  {
    name: "Pensamiento científico",
    pdfs: [
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20Pensamiento%20cient%C3%ADfico%20-%20Grado%201.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20Pensamiento%20cient%C3%ADfico%20-%20Grado%202.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20Pensamiento%20cient%C3%ADfico%20-%20Grado%203.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20Pensamiento%20cient%C3%ADfico%20-%20Grado%204.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20Pensamiento%20cient%C3%ADfico%20-%20Grado%205.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20Pensamiento%20cient%C3%ADfico%20-%20Grado%206.pdf",
    ],
  },
  {
    name: "Pensamiento matemático",
    pdfs: [
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Matem%C3%A1ticas%20internacional%20Dosificaci%C3%B3n%2024_25%20-%20Primer%20Grado.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Matem%C3%A1ticas%20internacional%20Dosificaci%C3%B3n%2024_25%20-%20Segundo%20Grado.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Matem%C3%A1ticas%20internacional%20%20Dosificaci%C3%B3n%2024_25%20-%20Tercer%20grado.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Matem%C3%A1ticas%20internacional%20Dosificaci%C3%B3n%2024_25%20-%20Cuarto%20Grado.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Matem%C3%A1ticas%20internacional%20Dosificaci%C3%B3n%2024_25%20-%20Quinto%20Grado.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Matem%C3%A1ticas%20internacional_%20Dosificaci%C3%B3n%2024_25%20-%206%20Grado%20Mate%20Internacional.pdf",
    ],
  },
  {
    name: "Gramática",
    pdfs: [
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20gram%C3%A1tica%20y%20lectoescritura%20-%20Primer%20Grado.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20gram%C3%A1tica%20y%20lectoescritura%20-%20Segundo%20Grado.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20gram%C3%A1tica%20y%20lectoescritura%20-%20Grado%203.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20gram%C3%A1tica%20y%20lectoescritura%20-%20Grado%204.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20gram%C3%A1tica%20y%20lectoescritura%20-%20Grado%205.pdf",
      "https://storage.googleapis.com/general-storage-prd/luca2-backend/pdf/Dosificaci%C3%B3n%20gram%C3%A1tica%20y%20lectoescritura%20-%20Grado%206.pdf",
    ],
  },
];
const grados = [
  "1º Grado",
  "2º Grado",
  "3º Grado",
  "4º Grado",
  "5º Grado",
  "6º Grado",
];

const DosificationDrawer = ({ onClose, visible }: PropsFilter) => {
  const isMobile = useIsMobile();
  const isPanelActive = (panelKey: string) => activePanels.includes(panelKey);

  const [activePanels, setActivePanels] = useState<string[]>([]);
  const handlePanelChange = (panelKey: string | string[]) => {
    setActivePanels(Array.isArray(panelKey) ? panelKey : [panelKey]);
    setSelectedCategory("");
  };
  const [pdfUrlToDownload, setPdfUrlToDownload] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = (category: string, i: number) => {
    const selectedCategoryObject = dosificationCategories.find(
      (item) => item.name === category
    );

    if (selectedCategoryObject) {
      const pdfs = selectedCategoryObject.pdfs;

      if (i >= 0 && i < pdfs.length) {
        const pdfUrl = pdfs[i];
        setPdfUrlToDownload(pdfUrl);

        if (selectedCategory === category) {
          return setSelectedCategory("");
        }
        return setSelectedCategory(category);
      }
    }
  };

  const handleSubmit = async () => {
    if (selectedCategory) {
      setIsLoading(true);
      try {
        const pdfUrl = pdfUrlToDownload;

        const partesDeUrl = pdfUrl.split("/");
        const nombreDelArchivo = partesDeUrl[partesDeUrl.length - 1];

        const response = await axios.get(pdfUrl, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], { type: "application/pdf" });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = nombreDelArchivo;
        link.style.display = "none";

        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(blobUrl);
        NotificationPositive(
          "¡Bien! Se ha descargado el archivo correctamente."
        );
      } catch (error) {
        console.error(error);
        notificationNegative(
          "¡Ups! Algo salió mal. Por favor, inténtalo de nuevo."
        );
      }
      setIsLoading(false);
    } else {
      notificationNegative("¡Ups! No se ha seleccionado una categoría.");
    }
  };

  return (
    <Drawer
      height={isMobile ? "400px" : ""}
      placement={isMobile ? "bottom" : "right"}
      width={500}
      onClose={onClose}
      open={visible}
      closable={false}
      style={{
        background: "white !important",
      }}
      title={
        <div className={styles.header}>
          <div className={styles.headerContainer}>
            <div>
              <img src={arrowback} alt="arrow back" onClick={onClose} />
            </div>
            <div className={styles.titleHeader}>
              <h1>Volver a lecciones</h1>
            </div>
            <img src={closeIcon} alt="close icon" onClick={onClose} />
          </div>
          <div className={styles.descriptionContainer}>
            Descarga las dosificaciones de Luca del curso 24/25 de Lenguajes,
            Pensamiento científico, Pensamiento matemático y Gramática.
          </div>
        </div>
      }
      footer={
        <div className={styles.footer}>
          <div className={styles.taskButton} onClick={handleSubmit}>
            Descargar dosificación
          </div>
        </div>
      }
    >
      {isLoading ? (
        <ActivityIndicator></ActivityIndicator>
      ) : (
        <Collapse
          className={styles.collapseContainer}
          bordered={false}
          accordion
          onChange={handlePanelChange}
          activeKey={activePanels}
        >
          {grados.map((grado, i) => {
            return (
              <Panel
                extra={
                  <div>
                    {isPanelActive(i.toString()) ? (
                      <img src={uparrow} alt="Panel Abierto" />
                    ) : (
                      <img src={downarrow} alt="Panel Cerrado" />
                    )}
                  </div>
                }
                showArrow={false}
                header={
                  <div
                    className={
                      isPanelActive(i.toString())
                        ? styles.clickedPanelHeader
                        : styles.panelHeader
                    }
                  >
                    {grado}
                  </div>
                }
                className={styles.panelStyle}
                key={i}
              >
                <List
                  dataSource={dosificationCategories}
                  renderItem={(dosificationCategory) => (
                    <List.Item className={styles.listItem}>
                      <Checkbox
                        checked={selectedCategory === dosificationCategory.name}
                        onChange={() => {
                          handleCheckboxChange(dosificationCategory.name, i);
                        }}
                      />
                      <div
                        className={
                          selectedCategory === dosificationCategory.name
                            ? styles.listItemChecked
                            : styles.listItem
                        }
                      >
                        {dosificationCategory.name}
                      </div>
                    </List.Item>
                  )}
                />
              </Panel>
            );
          })}
        </Collapse>
      )}
    </Drawer>
  );
};

export default DosificationDrawer;
