import { getHomeworkForLessonResolveDeepLink_getHomeworkForLessonResolveDeepLink } from "../../../../../__generated__/gql-types/getHomeworkForLessonResolveDeepLink";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";

export interface PropsChat {
  messages: PropsMessage[];
  individualLesson: getLessonById_getLessonById;
  isTyping: boolean;
  isFinished: boolean;
  activeSound: boolean;
  handleSendAlumn: (userMessage: string) => Promise<void>;
}
export interface PropsConversation {
  individualLesson: getLessonById_getLessonById;
  handleInstructions: () => void;
  statusHomework: getHomeworkForLessonResolveDeepLink_getHomeworkForLessonResolveDeepLink | null;
}
export interface PropsMessage {
  message: string;
  sender: string;
  direction?: string;
  messageUser?: string;
  notUnderstood?: boolean;
  position?: string;
  errorUser?: string | null;
}
const synth = window.speechSynthesis;
// const voices = synth.getVoices();
export const handleVoice = async (
  message: string,
  setIsSoundActive: (e: boolean) => void
) => {
  setIsSoundActive(true);
  const voices = await synth.getVoices();
  const usVoice = voices.find((e) => e.lang === "en-US");
  const ukVoice = voices.find((e) => e.lang === "en-GB");
  // console.log(usVoice);
  const utterThis = new SpeechSynthesisUtterance(message);
  if (usVoice) {
    utterThis.voice = usVoice;
  } else {
    if (ukVoice) {
      utterThis.voice = ukVoice;
    }
  }

  utterThis.onend = function () {
    setIsSoundActive(false);
  };
  synth.speak(utterThis);
};

export const extraerTextoEntreLlaves = (str: string) => {
  const inicio = str.indexOf("{") + 1;
  const fin = str.indexOf("}", inicio);
  if (inicio === 0 || fin === -1) {
    return ""; // Retorna cadena vacía si no se encuentra el patrón
  }
  return str.substring(inicio, fin);
};
export const allowedRoles = ["teacher", "coordinator", "admin"];
export const understoodVariants = [
  "Understood[0]",
  "understood[0]",
  "Understood [0]",
  "understood [0]",
  "Understood: [0]",
  "understood: [0]",
  "Understood:0",
  "understood:0",
  "Understood[ 0 ]",
  "understood[ 0 ]",
  "Understood: [ 0 ]",
  "understood: [ 0 ]",
  "Understood:0",
  "understood:0",
  "Understood [0]",
  "understood [0]",
  "Understood:[0]",
  "understood:[0]",
  "Understood [ 0 ]",
  "understood [ 0 ]",
];

export const apiKeyChatGpt =
  "sk-proj-iG50ls75kpOtnjRupgNaT3BlbkFJz5cyWRMNluYYv3XVqie7";
