import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { TeacherEventCorrectionInput } from "../__generated__/gql-types/globalTypes";

const useEditHomeworkEventResult = () => {
  const client = useApolloClient();

  return useCallback(
    async (teacherInput: TeacherEventCorrectionInput) => {
      return await client.mutate({
        mutation: EDIT_HOMEWORK_EVENT_RESULT,
        fetchPolicy: "no-cache",
        variables: { teacherInput },
      });
    },
    [client]
  );
};
export default useEditHomeworkEventResult;

const EDIT_HOMEWORK_EVENT_RESULT = gql`
  mutation editHomeworkEventResult($teacherInput: TeacherEventCorrectionInput) {
    editHomeworkEventResult(teacher_input: $teacherInput)
  }
`;
