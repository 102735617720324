import { useState } from "react";
import styles from "./newQuestions.module.scss";
import Latex from "react-latex";
import soundActive from "../../../../assets/audios/quiz/iconAudioQuizActive.svg";
import soundNegative from "../../../../assets/audios/quiz/iconAudioQuizNegative.svg";
import { getLessonById_getLessonById_lesson_content_quizzes_questions_answers } from "../../../../__generated__/gql-types/getLessonById";
import { ModalImg } from "./ModalImg";
import { synthesizeLanguageText } from "../NewQuizz/utils";

interface PropsNewQuestions {
  content: any;
  stateNext: boolean;
  answers: getLessonById_getLessonById_lesson_content_quizzes_questions_answers[];
  setAnswer: (
    e: getLessonById_getLessonById_lesson_content_quizzes_questions_answers[]
  ) => void;
  activeIndex: number[];
  setActiveIndex: (e: number[]) => void;
  putFocusFooter?: () => void;
  subject_id: number | null;
}

const NewQuestions = ({
  activeIndex,
  setActiveIndex,
  putFocusFooter,
  stateNext,
  answers,
  setAnswer,
  content,
  subject_id,
}: PropsNewQuestions) => {
  const [isSoundActive, setIsSoundActive] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const correctAnswers = content.answers.filter(
    (
      answer: getLessonById_getLessonById_lesson_content_quizzes_questions_answers
    ) => answer.correct === 1
  );
  const maxSelections = correctAnswers.length;

  const styleSelector = (index: number) => {
    return activeIndex.includes(index);
  };

  const styleNextStateValidation = (id: string) => {
    const isCorrect = correctAnswers.some(
      (
        e: getLessonById_getLessonById_lesson_content_quizzes_questions_answers
      ) => e.id === id
    );
    return activeIndex.includes(Number(id))
      ? isCorrect
        ? styles.answerTrue
        : styles.answerFalse
      : isCorrect
      ? styles.answerTrue
      : styles.afterRespQuizz;
  };

  const handleVoice = async () => {
    setIsSoundActive(true);
    subject_id && (await synthesizeLanguageText(content.question, subject_id));
    setIsSoundActive(false);
  };

  const handleSelection = (
    id: number,
    answer: getLessonById_getLessonById_lesson_content_quizzes_questions_answers
  ) => {
    if (stateNext) return;

    if (activeIndex.includes(id)) {
      const newActiveIndex = activeIndex.filter((index) => index !== id);
      const newAnswers = answers.filter((ans) => Number(ans.id) !== id);
      setActiveIndex(newActiveIndex);
      setAnswer(newAnswers);
    } else {
      if (activeIndex.length < maxSelections) {
        const newActiveIndex = [...activeIndex, id];
        const newAnswers = [...answers, answer];
        setActiveIndex(newActiveIndex);
        setAnswer(newAnswers);
      } else {
        const newActiveIndex = [...activeIndex.slice(1), id];
        const newAnswers = [...answers.slice(1), answer];
        setActiveIndex(newActiveIndex);
        setAnswer(newAnswers);
      }
    }

    putFocusFooter && putFocusFooter();
  };

  return (
    <div className={styles.content}>
      <div className={styles.fontQuestion}>
        {!isSoundActive && (
          <img
            className={styles.imgSound}
            src={soundActive}
            onClick={handleVoice}
            alt="default"
          />
        )}
        {isSoundActive && (
          <img className={styles.imgSound} src={soundNegative} alt="default" />
        )}
        {content.question && <Latex>{content.question}</Latex>}
      </div>

      {content.answers?.length > 2 && (
        <p className={styles.instruction}>
          Selecciona{" "}
          {maxSelections === 1 && answers.length > 2
            ? "una"
            : `${maxSelections}`}{" "}
          {maxSelections === 1 ? "opción" : "opciones"}
        </p>
      )}

      {content.url && (
        <img
          className={styles.imgQuestions}
          src={content.url ?? ""}
          alt="img"
          onClick={() => setOpen(true)}
        />
      )}
      {content.paragraph && (
        <div className={styles.containerParagrah}>
          <div className={styles.textParagrah}>{content.paragraph}</div>
        </div>
      )}

      <div className={styles.containerQuizz}>
        {content?.answers?.map(
          (
            individualAnswer: getLessonById_getLessonById_lesson_content_quizzes_questions_answers,
            index: number
          ) => (
            <div key={index} className={styles.containerAnswer}>
              <div
                className={
                  stateNext
                    ? styleNextStateValidation(individualAnswer?.id)
                    : styleSelector(Number(individualAnswer?.id))
                    ? styles.seleccionado
                    : styles.afterRespQuizz
                }
                onClick={() =>
                  handleSelection(
                    Number(individualAnswer?.id),
                    individualAnswer
                  )
                }
              >
                {individualAnswer?.answer ? (
                  <div className={styles.textQuiz}>
                    <Latex>{individualAnswer.answer}</Latex>
                  </div>
                ) : (
                  <img
                    src={individualAnswer?.url ?? ""}
                    alt="default"
                    width={"90px"}
                  />
                )}
              </div>
            </div>
          )
        )}
      </div>
      <ModalImg
        open={open}
        setOpen={setOpen}
        urlImg={content.url ?? ""}
      ></ModalImg>
    </div>
  );
};

export default NewQuestions;
