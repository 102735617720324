import { FC } from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./inputField.module.css";

interface InputFieldProps {
  defaultValue?: string;
  errors: any;
  touched: any;
  name: string;
  title: string;
  type: string;
  validate?: any;
  placeholder?: string;
}

const InputField: FC<InputFieldProps> = ({
  errors,
  touched,
  name,
  title,
  type,
  validate,
  placeholder,
  defaultValue,
}) => {
  return (
    <div>
      <label htmlFor={name}>{title}</label>
      <Field
        id={name}
        name={name}
        type={type}
        validate={validate}
        placeholder={placeholder}
        defaultValue={defaultValue ? defaultValue : undefined}
        className={
          errors[name] && touched[name]
            ? styles.formFieldsError
            : styles.formFields
        }
      />
      <ErrorMessage name={name} component="div" className={styles.error} />
    </div>
  );
};

export default InputField;
