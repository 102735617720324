import React from "react";
import { Link } from "react-router-dom";
import styles from "./siderMobileStudent.module.scss";
import avatarOn from "../../../../../assets/icons/siderStudent/avatar_on.png";
import avatarOff from "../../../../../assets/icons/siderStudent/avatar_off.png";
import misionesOff from "../../../../../assets/icons/siderStudent/misiones_off.png";
import misionesOn from "../../../../../assets/icons/siderStudent/misiones_on.png";
import MyProfile from "../../../../../assets/icons/MyProfile.svg";
import pointsMobileSelected from "../../../../../assets/icons/siderStudent/pointsMobileSelected.svg";
import bookIcon from "../../../../../assets/icons/teacher/book/book.svg";
import bookIconSelected from "../../../../../assets/icons/teacher/book/book-selected.svg";
import { getUserByEmail_getUserByEmail } from "../../../../../__generated__/gql-types/getUserByEmail";
import { isProgram } from "../DrawerNavigation/utils";

interface SiderStudentProps {
  path: string;
  activeButton: boolean;
  setPath: (e: string) => void;
  userDb: getUserByEmail_getUserByEmail | null | undefined;
  setVisible: (e: boolean) => void;
  onClose: () => void;
}
const SiderMobileStudent: React.FC<SiderStudentProps> = ({
  path,
  setPath,
  userDb,
  activeButton,
  setVisible,
  onClose,
}) => {
  const studentShop = userDb?.schools[0].view_shop;

  return (
    <>
      <Link
        to="/mymissions"
        className={styles.centerImage}
        onClick={() => {
          setPath("6");
          onClose();
        }}
      >
        <div
          className={
            path === "6"
              ? styles.containerImgSelected
              : styles.containerNotImgSelected
          }
        >
          <img
            src={path === "6" ? misionesOn : misionesOff}
            alt="Mis misiones"
          />
        </div>
        <div className={path === "6" ? styles.itemSelected : styles.item}>
          Mis misiones
        </div>
      </Link>

      {userDb && isProgram(userDb) && (
        <div className={styles.centerImage} onClick={() => setVisible(true)}>
          <div
            className={
              activeButton
                ? styles.containerImgSelected
                : styles.containerNotImgSelected
            }
          >
            <img
              src={activeButton ? bookIconSelected : bookIcon}
              alt="La Nem"
            />
          </div>
          <div className={activeButton ? styles.itemSelected : styles.item}>
            Explora
          </div>
        </div>
      )}

      {studentShop && (
        <Link
          to="/store"
          className={styles.centerImage}
          onClick={() => {
            setPath("19");
            onClose();
          }}
        >
          <div
            className={
              path === "19"
                ? styles.containerImgSelected
                : styles.containerNotImgSelected
            }
          >
            <img
              src={path === "19" ? avatarOn : avatarOff}
              alt="Programa 2425"
            />{" "}
          </div>
          <div className={path === "19" ? styles.itemSelected : styles.item}>
            Avatar
          </div>
        </Link>
      )}

      <Link
        to="/myProfile"
        className={styles.centerImage}
        onClick={() => {
          setPath("12");
          onClose();
        }}
      >
        <div
          className={
            path === "12"
              ? styles.containerImgSelected
              : styles.containerNotImgSelected
          }
        >
          <img
            src={path === "12" ? pointsMobileSelected : MyProfile}
            alt="Mis clases"
          />{" "}
        </div>
        <p className={path === "12" ? styles.itemSelected : styles.item}>
          Mi perfil
        </p>
      </Link>
    </>
  );
};

export default SiderMobileStudent;
