import { useEffect, useState } from "react";
import { Checkbox, List } from "antd";
import styles from "./assignCourseLessons.module.scss";
import UseGetLessonsByCourse from "../../../../api/useGetLessonByCourse";
import { getLessonsByCourse_getLessonsByCourse } from "../../../../__generated__/gql-types/getLessonsByCourse";
import { Loader } from "../../../Commons";
import defaultImage from "../../../../assets/images/defaultImage.svg";
import thumbnailClase from "../../../../assets/images/thumbnail/thumbnailOrientacionDidactica.png";
import thumbnailCiencias from "../../../../assets/images/thumbnail/thumbnailCiencias.png";
import thumbnailMate from "../../../../assets/images/thumbnail/thumbnailMate.png";
import CoverReading from "../../../../assets/images/thumbnail/CoverReading.png";
import CoverLectura from "../../../../assets/images/thumbnail/CoverLectura.png";
import thumbnailEspañol from "../../../../assets/images/thumbnail/thumbnailEspañol.png";
import thumbnailDiapositivas from "../../../../assets/images/thumbnail/thumbnailDiapositivas.png";
import thumbnailBookPages from "../../../../assets/images/thumbnail/thumbnailBookPages.png";
import ensayoMatematica from "../../../../assets/images/thumbnail/ensayoMatematica.png";
import ensayoEspañol from "../../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../../assets/images/thumbnail/ensayoCiencias.png";
const AssignCourseLessons = ({
  courseId,
  lessonId,
  setSelectedCourseLessons,
  selectedCourseLessons,
}: {
  courseId: number;
  lessonId: number;
  selectedCourseLessons: number[];
  setSelectedCourseLessons: (p: number[]) => void;
}) => {
  const getLessonsByCourse = UseGetLessonsByCourse();
  const [loading, setLoading] = useState(false);
  const [filteredLessons, setFilteredLessons] =
    useState<getLessonsByCourse_getLessonsByCourse[]>();
  const functionReturnImg = (lesson: getLessonsByCourse_getLessonsByCourse) => {
    if (lesson.lesson_type === "classroomguide") {
      return thumbnailClase;
    }
    if (lesson.lesson_type === "slides") {
      return thumbnailDiapositivas;
    }
    if (lesson.lesson_type === "book_pages") {
      return thumbnailBookPages;
    }
    if (lesson.lesson_type === "evaluation") {
      if (lesson.subject?.id === "1") {
        return thumbnailMate;
      }
      if (lesson.subject?.id === "2") {
        return thumbnailEspañol;
      }
      if (lesson.subject?.id === "4") {
        return thumbnailCiencias;
      }
    }
    if (lesson.lesson_type === "redaction") {
      if (lesson.subject?.id === "1") {
        return ensayoMatematica;
      }
      if (lesson.subject?.id === "2") {
        return ensayoEspañol;
      }
      if (lesson.subject?.id === "4") {
        return ensayoCiencias;
      }
    }
    if (lesson.lesson_type === "literacy") {
      if (lesson.subject?.id === "16") {
        return CoverReading;
      }
      return CoverLectura;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getLessonsByCourse({
          getLessonsByCourseInput: {
            course_id: courseId,
            section: "english",
          },
        });
        const filteredResponseLessons = response?.filter((lesson) => {
          const allowedTypes = [
            "redaction",
            "videolesson",
            "evaluation",
            "reading",
          ];
          return (
            lesson &&
            allowedTypes.includes(lesson.lesson_type || "") &&
            Number(lesson.id) !== lessonId
          );
        });
        setFilteredLessons(filteredResponseLessons);
        setLoading(false);
      } catch (error) {
        alert(`Ocurrio un error: ${error}`);
      }
    };

    fetchData();
  }, [courseId]);

  const handleCheckboxChange = (lessonId: number) => {
    const updatedSelectedLessons = [...selectedCourseLessons];
    const lessonIndex = updatedSelectedLessons.indexOf(lessonId);
    if (lessonIndex === -1) {
      updatedSelectedLessons.push(lessonId);
    } else {
      updatedSelectedLessons.splice(lessonIndex, 1);
    }
    setSelectedCourseLessons(updatedSelectedLessons);
  };

  return loading ? (
    <Loader />
  ) : filteredLessons?.length ? (
    <>
      <div className={styles.headerText}>
        Asignar a la vez más tareas relacionadas con este curso, con las mismas
        condiciones seleccionadas más abajo.
      </div>
      <List
        dataSource={filteredLessons}
        renderItem={(item, i) => (
          <List.Item className={styles.listItem}>
            <div
              className={styles.listItemContainer}
              onClick={() => handleCheckboxChange(Number(item.id))}
            >
              <img
                className={styles.containerImg}
                src={
                  functionReturnImg(item)
                    ? functionReturnImg(item)
                    : item.lesson_content?.videos?.length &&
                      item.lesson_content?.videos[0]?.imageUrl
                    ? item.lesson_content?.videos[0].imageUrl
                    : item.lesson_content?.literacy?.cover
                    ? item.lesson_content?.literacy.cover
                    : defaultImage
                }
                alt="img"
              />
              <div className={styles.containerContent}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.lessonType}>
                  {item?.lesson_type === "videolesson"
                    ? "Video Lección"
                    : item?.lesson_type === "classroomguide"
                    ? "Orientación didáctica"
                    : item?.lesson_type === "redaction"
                    ? "Redacción"
                    : item?.lesson_type === "evaluation"
                    ? "Evaluación"
                    : item?.lesson_type === "slides"
                    ? "Diapositivas"
                    : item?.lesson_type === "book_pages"
                    ? "Libro"
                    : "Video Lección"}
                </div>
              </div>
              <Checkbox
                className={styles.checkBox}
                checked={selectedCourseLessons.includes(Number(item.id))}
                onChange={() => handleCheckboxChange(Number(item.id))}
                name={item.title || undefined}
              />
            </div>
          </List.Item>
        )}
      />
    </>
  ) : (
    <p style={{ fontFamily: "Poppins-Regular" }}>
      No hay más lecciones que puedas asignar en este curso.
    </p>
  );
};

export default AssignCourseLessons;
