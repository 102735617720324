import styles from "./siderMobile.module.scss";
import useAuth from "../../../../context/useAuth";
import { useState } from "react";
import { DrawerNavigation } from "./DrawerNavigation";
import { SiderMobileStudent } from "./SiderMobileStudent";
import { SiderMobileTeacher } from "./SiderMobileTeacher";
import { getUserByEmail_getUserByEmail } from "../../../../__generated__/gql-types/getUserByEmail";

interface PropsSider {
  path: string;
  setPath: (e: string) => void;
  userDb: getUserByEmail_getUserByEmail | null | undefined;
}
const SiderMobile = ({ path, setPath, userDb }: PropsSider) => {
  const { userToken } = useAuth();
  const activeButton = ["11", "13", "17", "18", "24", "15"].includes(path);
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className={styles.siderMobile}>
      {userToken?.role === "student" ? (
        <SiderMobileStudent
          path={path}
          setPath={setPath}
          activeButton={activeButton}
          userDb={userDb}
          setVisible={setVisible}
          onClose={onClose}
        />
      ) : (
        <SiderMobileTeacher
          activeButton={activeButton}
          onClose={onClose}
          path={path}
          setVisible={setVisible}
          setPath={setPath}
        />
      )}
      {visible && userDb && (
        <DrawerNavigation onClose={onClose} userDb={userDb} />
      )}
    </div>
  );
};

export default SiderMobile;
